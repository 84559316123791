import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import { DurationInputArg2, Moment } from 'moment';
import { Dates } from '@app/analytics/metric-widget/query/outline/Dates';

export class TimeScale {
  private values: number[] = [];

  constructor(
    public resolution: Resolution,
    public getPointLabel: (value: number) => string,
    private name: DurationInputArg2,
    private format: string,
    public longFormat: string,
    // tslint:disable:variable-name
    private _updateMoment: (momentObj: Moment, values: number) => {},
    private _fromMoment: (momentObj: Moment) => number,
    private _get: (dates: Dates) => number[],
    private _set: (dates: Dates, values: number[]) => {},
    public isUsedToInitialize: boolean = false
  ) {}

  public shiftMoment(momentObj: Moment, amount: number): void {
    momentObj.add(amount, this.name);
  }

  public select(blocks: number[]): void {
    this.values = blocks;
    this.values.sort();
  }

  public hasValues(): boolean {
    return this.values.length > 0;
  }

  public getValues(): number[] {
    return this.values;
  }

  public isSingleValue(): boolean {
    return this.values.length === 1;
  }

  public getSingleValue(): number {
    return this.values[0];
  }

  public updateMoment(momentObj: Moment): void {
    this._updateMoment(momentObj, this.values[0]);
  }

  public toDates(dates: Dates): void {
    this._set(dates, this.values);
  }

  public fromDates(dates: Dates): void {
    const update = this._get(dates);

    if (update != null && update.length > 0) {
      this.values = update;
    }
  }

  public reset(): void {
    this.values = [];
  }

  public setSingleValue(momentObj: Moment): void {
    this.values = [this._fromMoment(momentObj)];
  }

  public isActive(value: number): boolean {
    return this.values.includes(value);
  }

  public getLabel(time: Moment): string {
    return this.values
      .map((value) => {
        this._updateMoment(time, value);
        return time.format(this.format);
      })
      .join(', ');
  }
}
