import { Injectable } from '@angular/core';
import { BeaconSettingResource } from '@app/shared/resources/beacon-settings.resource';
import { BeaconSetting } from '@app/shared/models/beacons-setting.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { SavedEntity } from '@app/api/SavedEntity';

@Injectable({
  providedIn: 'root'
})
export class BeaconSettingService {
  private beconSettingsContext = new BehaviorSubject<BeaconSetting>(null);

  getBeaconSettingsContext(): Observable<BeaconSetting> {
    return this.beconSettingsContext.asObservable();
  }

  constructor(private beaconSettingResource: BeaconSettingResource) {}

  activate(nodeIds: number[], enabled: boolean, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.activate(nodeIds, enabled, buildingId);
  }

  updatePowerLevel(nodeIds: number[], powerLevel: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.updatePowerLevel(nodeIds, powerLevel, buildingId);
  }

  updateContent(nodeIds: number[], content: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.updateContent(nodeIds, content, buildingId);
  }

  updateInterval(nodeIds: number[], interval: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.updateInterval(nodeIds, interval, buildingId);
  }

  updateUuid(nodeIds: number[], uuid: string, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.updateUuid(nodeIds, uuid, buildingId);
  }

  updateMajor(nodeIds: number[], major: string, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.updateMajor(nodeIds, major, buildingId);
  }

  updateMinor(nodesIds: number[], minor: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.updateMinor(nodesIds, minor, buildingId);
  }

  querySensorNodes(nodeIds: number[], buildingId: number): Observable<SavedEntity<BeaconSetting, number>> {
    return this.beaconSettingResource.querySensorNodes(nodeIds, buildingId);
  }

  getBeaconSettingsForFloorAndNodeIds(floorId: number, nodeIds: number[]): Observable<BeaconSetting[]> {
    return this.beaconSettingResource.getBeaconSettingsForFloorAndNodeIds(floorId, nodeIds);
  }

  updateSettingForSelectedNode(beaconSettings: BeaconSetting): void {
    this.beconSettingsContext.next(beaconSettings);
  }
}
