import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PanelComponent } from '@app/beacons/configuration/panel/panel.component';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { FeatureService } from '@services/feature.service';
import { BeaconSetting, contents, intervals, powers, uuids } from '@app/shared/models/beacons-setting.interface';
import { BeaconSettingService } from '@services/beacon-setting.service';
import { SensorNodeService } from '@services/sensor-node.service';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { MatSlider } from '@angular/material/slider';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { SensorNode } from '@app/shared/models/sensor-node';
import { Selectable } from '@app/shared/models/selectable.interface';
import { PanelFooterComponent } from '@app/beacons/configuration/panel-footer/panel-footer.component';

@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    PanelComponent,
    AuthorizationModule,
    SharedComponentsModule,
    MatSlider,
    PanelFooterComponent
  ],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent implements OnInit, OnChanges {
  @Input() buildingId: number;
  @Input() floorId!: number;
  @Input() isReady: boolean;
  isBusy = false;
  showPanelBasedOnFeature = false;

  status = false;

  powerLevel: string;
  indexedPower = 0;
  powers = powers;

  contents = contents;
  content = contents[0];

  uuids = uuids;

  interval: any;
  intervals = intervals;
  indexedInterval = 0;

  uuid = null;
  major = null;
  minor = null;

  private originalStatus: boolean;
  private originalIndexedPower: number;
  private originalContent;
  private originalIndexedInterval = 0;
  private originalUuid: string;
  private originalMajor: string;
  private originalMinor: number;
  private selectedSensorNodes: Selectable[] | SensorNode[];

  constructor(
    private beaconSettingService: BeaconSettingService,
    private snService: SensorNodeService,
    private toast: ToastService,
    private dialogService: ConfirmationDialogService,
    private featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.isBusy = false;
    this.originalStatus = this.status;
    this.originalIndexedPower = this.indexedPower;
    this.originalContent = this.content;
    this.originalUuid = this.uuid;
    this.beaconSettingService.getBeaconSettingsContext().subscribe((beaconSettings) => {
      this.updatePanel(beaconSettings);
    });
    this.convert();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buildingId) {
      const newBuildingId = changes.buildingId.currentValue;
      this.featureService.isAvailableForBuildingId('beaconNimway', newBuildingId).subscribe((isAvailable) => {
        this.showPanelBasedOnFeature = isAvailable;
      });
    }
  }

  get MINOR_GENERATE(): number {
    return -1;
  }

  private updatePanel(update: BeaconSetting): void {
    this.convert();

    if (update != null) {
      if (update.enabled) {
        this.status = update.enabled;
      }
      if (update.powerLevel) {
        this.indexedPower = this.powers.indexOf(update.powerLevel);
        this.convert();
      }
      if (update.content) {
        const content = contents.find((item) => item.value === update.content);
        this.content = content ? content : contents[0];
      }
      if (update.beaconInterval) {
        this.intervals.forEach((item, i) => {
          if (item.value === update.beaconInterval) {
            this.indexedInterval = i;
          }
        });
      }
      if (update.uuid != null) {
        const uuid = uuids.find((item) => item.value === update.uuid);
        this.uuid = uuid ? uuid : uuids[0];
      }
      this.major = update.major ? update.major : null;
      this.minor = update.minor ? update.minor : null;
    }
  }

  resetStatus(): void {
    this.status = this.originalStatus;
  }

  resetPower(): void {
    this.indexedPower = this.originalIndexedPower;
    this.convert();
  }

  resetContent(): void {
    this.content = this.originalContent;
  }

  resetInterval(): void {
    this.indexedInterval = this.originalIndexedInterval;
  }

  resetUuid(): void {
    this.uuid = this.originalUuid;
  }

  resetMajor(): void {
    this.major = this.originalMajor;
  }

  resetMinor(): void {
    this.minor = this.originalMinor;
  }

  setStatus(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    this.isBusy = true;

    this.beaconSettingService.activate(this.getSelectedNodeIds(), this.status, this.buildingId).subscribe({
      next: () => {
        this.originalStatus = this.status;
        this.isBusy = false;
        this.toast.success({
          message: `The nodes are now ${this.status === true ? 'Enabled' : 'Disabled'}.`,
          dataCy: `node-enable-disable-success-toast`
        });
      },
      error: () => {
        this.isBusy = false;
        this.toast.error({
          message: `Something went wrong!.`,
          dataCy: `power-level-failure-toast`
        });
      }
    });
  }

  setPowerLevel(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    this.isBusy = true;

    this.beaconSettingService
      .updatePowerLevel(this.getSelectedNodeIds(), this.powers[this.indexedPower], this.buildingId)
      .subscribe({
        next: () => {
          this.originalIndexedPower = this.indexedPower;
          this.convert();
          this.isBusy = false;
          this.toast.success({
            message: `The new power level is ${this.powerLevel}.`,
            dataCy: `power-level-success-toast`
          });
        },
        error: () => {
          this.isBusy = false;
          this.toast.error({
            message: `Something went wrong!.`,
            dataCy: `power-level-failure-toast`
          });
        }
      });
  }

  setContent(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    this.isBusy = true;
    if (this.content) {
      this.beaconSettingService
        .updateContent(this.getSelectedNodeIds(), this.content.value, this.buildingId)
        .subscribe({
          next: () => {
            this.originalContent = this.content;
            this.isBusy = false;
            this.toast.success({
              message: `The beacon content has been set to ${this.content.name}.`,
              dataCy: `content-success-toast`
            });
          },
          error: () => {
            this.isBusy = false;
            this.toast.error({
              message: `Something went wrong!.`,
              dataCy: `content-failure-toast`
            });
          }
        });
    }
  }

  setInterval(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    const interval = this.intervals[this.indexedInterval];
    if (interval.value === 100) {
      const data = new ConfirmDialogData(
        'Repeating beacons at this rate may cause additional RF interference within the building.Continue anyway?'
      );
      this.dialogService.open(data).subscribe((result) => {
        if (!result) {
          return;
        }
      });
    }
    this.isBusy = true;

    this.beaconSettingService.updateInterval(this.getSelectedNodeIds(), interval.value, this.buildingId).subscribe({
      next: () => {
        this.originalIndexedInterval = this.indexedInterval;
        this.isBusy = false;
        this.toast.success({
          message: `The beacon repetition has been set to  ${interval.name}.`,
          dataCy: `interval-success-toast`
        });
      },
      error: () => {
        this.isBusy = false;
        this.toast.error({
          message: `Something went wrong!.`,
          dataCy: `interval-failure-toast`
        });
      }
    });
  }

  setUuid(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    this.isBusy = true;
    this.beaconSettingService.updateUuid(this.getSelectedNodeIds(), this.uuid.value, this.buildingId).subscribe({
      next: () => {
        this.originalUuid = this.uuid;
        this.isBusy = false;
        this.toast.success({
          message: `The beacon UUID has been set to  ${this.uuid.name}.`,
          dataCy: `uuid-success-toast`
        });
      },
      error: () => {
        this.isBusy = false;
        this.toast.error({
          message: `Something went wrong!.`,
          dataCy: `uuid-failure-toast`
        });
      }
    });
  }

  setMajor(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    this.isBusy = true;
    if (!this.major || this.major < 0 || this.major > 65534) {
      this.isBusy = false;
      this.toast.warning({
        message: `Major value should be between 0 to 65535.`,
        dataCy: `major-warning-toast`
      });
    } else {
      this.beaconSettingService.updateMajor(this.getSelectedNodeIds(), this.major, this.buildingId).subscribe({
        next: () => {
          this.originalMajor = this.major;
          this.isBusy = false;
          this.toast.success({
            message: `The beacon major has been set to ${this.major}.`,
            dataCy: `major-success-toast`
          });
        },
        error: () => {
          this.isBusy = false;
          this.toast.error({
            message: `Something went wrong!.`,
            dataCy: `major-failure-toast`
          });
        }
      });
    }
  }

  setMinor(): void {
    this.selectedSensorNodes = this.snService.selectedEntities;
    this.isBusy = true;
    this.beaconSettingService.updateMinor(this.getSelectedNodeIds(), this.MINOR_GENERATE, this.buildingId).subscribe({
      next: (beaconSettings) => {
        this.isBusy = false;
        if (beaconSettings && beaconSettings.length === 1) {
          this.minor = beaconSettings[0].minor;
          this.originalMinor = beaconSettings[0].minor;
          this.toast.success({
            message: `The beacon minor has been set to ${this.minor}.`,
            dataCy: `minor-success-toast`
          });
        } else {
          this.toast.success({
            message: `The beacon minor has been generated.`,
            dataCy: `minor-generated-toast`
          });
        }
      },
      error: () => {
        this.isBusy = false;
        this.toast.error({
          message: `Something went wrong!.`,
          dataCy: `minor-failure-toast`
        });
      }
    });
  }

  convert(): void {
    this.powerLevel = this.powers[this.indexedPower] + ' dBm';
  }

  private getSelectedNodeIds(): number[] {
    return this.selectedSensorNodes.map((node: SensorNode): number => node.id);
  }
}
