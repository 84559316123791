import { NgModule } from '@angular/core';
import { FailedTestsCounterComponent } from '@app/shared/components/floorplan/failed-tests-counter/failed-tests-counter.component';
import { FloorplanZoomControlComponent } from '@app/shared/components/floorplan/floorplan-zoom-control/floorplan-zoom-control.component';
import { SensorNodeComponent } from '@app/shared/components/floorplan/sensor-node/sensor-node.component';
import { EmDriverComponent } from '@app/shared/components/floorplan/sensor-node/em-driver/em-driver.component';
import { LuminaireDriverComponent } from '@app/shared/components/floorplan/sensor-node/luminaire-driver/luminaire-driver.component';
import { FloorplanComponent } from '@app/shared/components/floorplan/floorplan.component';
import { CommonModule } from '@angular/common';
import { TagOutlineDirective } from '@components/floorplan/sensor-node/tag-outline.directive';
import { INITIAL_SCALE, MAXIMUM_SCALE, MINIMUM_SCALE, SCALE_FACTOR } from '@services/image-zoom.service';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { PubSubConnectionsDirective } from '@components/floorplan/sensor-node/pub-sub-connections.directive';
import { FloorplanActionsTrayComponent } from '@components/floorplan/floorplan-actions-tray/floorplan-actions-tray.component';
import { FloorplanActionsComponent } from '@components/floorplan/floorplan-actions/floorplan-actions.component';
import { HeatmapComponent } from '@components/heatmap/heatmap.component';

@NgModule({
  imports: [
    AuthorizationModule,
    CdkDropList,
    CommonModule,
    FloorplanActionsComponent,
    FloorplanActionsTrayComponent,
    MatButtonModule,
    MatInputModule,
    PubSubConnectionsDirective,
    ReactiveFormsModule,
    TagOutlineDirective,
    HeatmapComponent
  ],
  providers: [
    { provide: INITIAL_SCALE, useValue: 1.0 },
    { provide: MINIMUM_SCALE, useValue: 0.1 },
    { provide: MAXIMUM_SCALE, useValue: 2.0 },
    { provide: SCALE_FACTOR, useValue: 1.2 }
  ],
  declarations: [
    EmDriverComponent,
    FloorplanComponent,
    FailedTestsCounterComponent,
    FloorplanZoomControlComponent,
    LuminaireDriverComponent,
    SensorNodeComponent
  ],
  exports: [
    EmDriverComponent,
    FloorplanComponent,
    FailedTestsCounterComponent,
    FloorplanZoomControlComponent,
    LuminaireDriverComponent,
    SensorNodeComponent
  ]
})
export class FloorplanModule {}
