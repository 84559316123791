import { Component, Input } from '@angular/core';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { PanelFooterComponent } from '@app/beacons/configuration/panel-footer/panel-footer.component';

@Component({
  selector: 'app-panel',
  standalone: true,
  imports: [SharedComponentsModule, PanelFooterComponent],
  templateUrl: './panel.component.html',
  styleUrl: './panel.component.scss'
})
export class PanelComponent {
  @Input() title: string;

  constructor() {}
}
