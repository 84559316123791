import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { DdlNode, DdlTag, DdlMode, DdlSetting } from '@app/shared/models/ddl-setting.interface';
import { DdlService } from '@services/ddl.service';
import { ToastService } from '@services/toast/toast.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-ddl',
  standalone: true,
  templateUrl: './ddl.component.html',
  imports: [FormsModule, ReactiveFormsModule, MatTooltip, MatOption, MatSelect, MatSlideToggle, MatInput, MatButton],
  styleUrl: './ddl.component.scss'
})
export class DdlComponent implements OnInit {
  ddlMode: DdlMode;
  ddlNode: DdlNode;
  buildingId: number;
  ddlTags: DdlTag[];
  ddlTagId: string;
  ddlForm: FormGroup;
  private isNew: boolean;
  private setting: DdlSetting;

  constructor(
    private route: ActivatedRoute,
    private ddlService: DdlService,
    private fb: FormBuilder,
    private toaster: ToastService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.buildingId = params.buildingId;
      this.loadTags();
      this.loadSetting(this.buildingId);
    });
  }

  setForm(): void {
    this.ddlForm = this.fb.group({
      modeEnabled: [this.ddlMode?.enabled, Validators.required],
      address: [{ value: this.setting?.address, disabled: !this.ddlMode?.enabled }, Validators.required],
      ddlTagId: [
        { value: this.setting?.luminaireTagId.toString(), disabled: !this.ddlMode?.enabled },
        Validators.required
      ]
    });
  }

  private loadSetting(buildingId: number): void {
    this.ddlService.getDdlSetting(buildingId).subscribe({
      next: (ddlSetting) => {
        if (ddlSetting) {
          this.isNew = false;
          this.setting = ddlSetting;
          this.getDdlNode(ddlSetting.address);
        } else {
          this.isNew = true;
        }
        this.getDdlMode();
      }
    });
  }

  private loadTags(): void {
    this.ddlService.getDdlTags(this.buildingId).subscribe({
      next: (ddlTags: DdlTag[]) => {
        this.ddlTags = ddlTags;
      }
    });
  }

  private getDdlMode(): void {
    this.ddlService.getDdlMode(this.buildingId).subscribe({
      next: (ddlMode) => {
        if (ddlMode) {
          this.ddlMode = ddlMode;
        } else {
          this.ddlService.createDdlMode(this.buildingId).subscribe((mode: DdlMode) => {
            this.ddlMode = mode;
          });
        }
        this.setForm();
      }
    });
  }

  updateDdlMode(): void {
    this.ddlMode.enabled = this.ddlForm.value.modeEnabled;
    if (this.ddlMode.enabled) {
      this.ddlForm.get('address').enable();
      this.ddlForm.get('ddlTagId').enable();
    } else {
      this.ddlForm.get('address').disable();
      this.ddlForm.get('ddlTagId').disable();
    }
    this.ddlService
      .updateDdlMode(this.ddlMode.enabled, this.ddlMode.id, this.buildingId)
      .subscribe((ddlMode: DdlMode) => {
        this.ddlMode = ddlMode;
      });
  }

  getDdlNode(value: number): void {
    if (value) {
      this.ddlNode = {
        address: value,
        buildingId: this.buildingId
      };
    }
  }

  saveButtonPressed(): void {
    const ddlSetting: DdlSetting = {
      outdoorSensorNodeId: null,
      address: this.ddlForm.value.address,
      buildingId: this.buildingId,
      luminaireTagId:
        typeof this.ddlForm.value.dlTagId === 'string'
          ? parseInt(this.ddlForm.value.dlTagId, 10)
          : this.ddlForm.value.ddlTagId
    };
    if (this.isNew) {
      this.ddlService.createDdlSetting(ddlSetting).subscribe((response: DdlSetting) => {
        this.update(response);
        this.toaster.success({ message: 'Created ddl setting successfully!', dataCy: 'save-success-toast' });
      });
    } else {
      this.ddlService.updateDdlSetting(ddlSetting, this.setting.id).subscribe((response: DdlSetting) => {
        this.update(response);
        this.toaster.success({ message: 'Updated ddl setting successfully!', dataCy: 'save-success-toast' });
      });
    }
  }

  private update(setting: DdlSetting): void {
    this.setting = setting;
    this.isNew = false;
    this.getDdlNode(setting.address);
  }
}
