import { Tag } from './tag.interface';
import { Tenant } from '@app/shared/models/tenant.interface';
import { DuplicateMapping, DuplicateType } from '@app/shared/models/duplicate-mapping';
import { LuminaireDriver, LuminaireDriverDTO, SelectableLuminaire } from '@app/shared/models/luminaire-driver';
import { EmDriver, EmDriverDTO, SelectableEmDriver } from '@app/shared/models/em-driver';
import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { SensorNodeStatus } from '@app/shared/models/sensor-node-status';
import { LightingConfiguration } from '@app/shared/models/lighting-configuration';
import { StringUtils } from '@app/shared/utils/string.utils';
import { BeaconSetting } from '@app/shared/models/beacons-setting.interface';

export interface SensorNodeDTO {
  id?: number;
  x: number;
  y: number;
  address: number;
  tags: Tag[];
  tenants: Tenant[];
  connected: boolean;
  timeSinceInstall: number;
  duplicateAddressMappings: DuplicateMapping[];
  duplicateGroupMappings: DuplicateMapping[];
  isChanged: boolean;
  isNotifying: boolean;
  floorId: number;
  bleKey: number;
  installedAt: Date;
  nodeType: string;
  properlyMapped: boolean;
  updatedAt: Date;
  scene: number;
  beaconSetting?: BeaconSetting;
  lightingConfiguration: LightingConfiguration;
  emDrivers: EmDriverDTO[] | SelectableEmDriver[];
  luminaireDrivers: LuminaireDriverDTO[] | SelectableLuminaire[];
  isDriverEmergencyLightingTestStarted: boolean;
  isTooManyDriverEmergencyLightingTest: boolean;
  groupId: number;
  subscriber: boolean;
  hasMappingBeenAttempted: boolean;
  bleScanning: string;
  valueSuffix?: string;
  isFaulty: boolean;
}

export interface SelectableNode extends SensorNodeDTO, Selectable, h337.HeatmapDataPoint {
  isSN3: boolean;
  isPassiveNode: boolean;
  isHIM84: boolean;
  isHCD405: boolean;
  isEnvironmental: boolean;
}

export interface EnhancedSelectable extends Selectable {
  groupId?: number;
  address?: number;
  properlyMapped?: boolean;
  hasMappingBeenAttempted?: boolean;
}

export class SensorNodeAlert {
  constructor(public value: string, public link?: string) {}
}

export class SensorNode implements SelectableNode {
  public static PASSIVE_NODE_TYPE = DISCRIMINATOR.PN.toString();
  public static SN3_NODE_TYPE = DISCRIMINATOR.SN3.toString();
  public static HIM84_NODE_TYPE = DISCRIMINATOR.HIM84.toString();
  public static HCD405_NODE_TYPE = DISCRIMINATOR.HCD405.toString();
  public static FUJITSU_ENV = DISCRIMINATOR.FUJITSU_ENV.toString();
  public static FUJITSU_ENV_C02 = DISCRIMINATOR.FUJITSU_ENV_CO2.toString();

  id?: number;
  address: number;
  bleKey: number;
  beaconSetting: BeaconSetting;
  lightingConfiguration: LightingConfiguration;
  connected: boolean;
  duplicateAddressMappings: DuplicateMapping[];
  duplicateGroupMappings: DuplicateMapping[];
  emDrivers: EmDriver[];
  floorId: number;
  groupId: number;
  installedAt: Date;
  isChanged: boolean;
  isNotifying: boolean;
  isDriverEmergencyLightingTestStarted: boolean;
  isTooManyDriverEmergencyLightingTest: boolean;
  luminaireDrivers: LuminaireDriver[];
  nodeType: string;
  properlyMapped: boolean;
  scene: number;
  subscriber: boolean;
  tags: Tag[];
  tenants: Tenant[];
  timeSinceInstall: number;
  updatedAt: Date;
  x: number;
  y: number;
  presence: number;
  lightLevel: number;
  value = 0;
  radius = 0;
  hasMappingBeenAttempted = false;
  bleScanning = null;
  valueSuffix?: string;
  max = 100;
  constructor(dto: SensorNodeDTO) {
    this.id = dto.id;
    this.address = dto.address;
    this.bleKey = dto.bleKey;
    this.connected = dto.connected;
    this.duplicateAddressMappings = dto.duplicateAddressMappings || [];
    this.duplicateGroupMappings = dto.duplicateGroupMappings || [];
    this.emDrivers = EmDriver.ofMultiple(dto.emDrivers);
    this.floorId = dto.floorId;
    this.groupId = dto.groupId;
    this.installedAt = dto.installedAt;
    this.isChanged = dto.isChanged;
    this.isDriverEmergencyLightingTestStarted = dto.isDriverEmergencyLightingTestStarted;
    this.isTooManyDriverEmergencyLightingTest = dto.isTooManyDriverEmergencyLightingTest;
    this.luminaireDrivers = LuminaireDriver.ofMultiple(dto.luminaireDrivers);
    this.nodeType = dto.nodeType;
    this.properlyMapped = dto.properlyMapped;
    this.scene = dto.scene;
    this.subscriber = dto.subscriber;
    this.tags = dto.tags || [];
    this.tenants = dto.tenants;
    this.timeSinceInstall = dto.timeSinceInstall;
    this.updatedAt = dto.updatedAt;
    this.x = dto.x;
    this.y = dto.y;
    this.bleScanning = dto.bleScanning;
    this.valueSuffix = dto.valueSuffix;
    this.isNotifying = dto.isNotifying || false;
    this.beaconSetting = dto.beaconSetting;
    this.lightingConfiguration = dto.lightingConfiguration;
  }

  get discriminator(): DISCRIMINATOR {
    for (const idx in DISCRIMINATOR) {
      if (this.nodeType === DISCRIMINATOR[idx].toString()) {
        return DISCRIMINATOR[idx];
      }
    }
    // If we can't decide use SN3
    return DISCRIMINATOR.SN3;
  }

  get isPassiveNode(): boolean {
    return this.nodeType === SensorNode.PASSIVE_NODE_TYPE;
  }

  get isHIM84(): boolean {
    return this.nodeType === SensorNode.HIM84_NODE_TYPE;
  }

  get isHCD405(): boolean {
    return this.nodeType === SensorNode.HCD405_NODE_TYPE;
  }

  get isSN3(): boolean {
    return this.nodeType == null || this.nodeType === SensorNode.SN3_NODE_TYPE;
  }

  get isEnvironmental(): boolean {
    return this.nodeType === SensorNode.FUJITSU_ENV_C02 || this.nodeType === SensorNode.FUJITSU_ENV;
  }

  get bleScanningState(): string {
    if (this.isPassiveNode || this.isEnvironmental) {
      return 'ON';
    } else {
      return this.bleScanning ?? 'Unknown, please query';
    }
  }

  get beaconEnabled(): boolean {
    if (this.beaconSetting == null || this.beaconSetting.enabled == null) {
      return false;
    } else {
      return this.beaconSetting.enabled;
    }
  }

  get beaconPowerLevel(): string {
    if (this.beaconSetting == null || this.beaconSetting.powerLevel == null) {
      return 'N/A';
    } else {
      return (this.beaconSetting.powerLevel > 0 ? '+' : '') + this.beaconSetting.powerLevel + ' dBm';
    }
  }

  public get beaconContent(): { name: string; value: string } {
    if (this.beaconSetting == null || this.beaconSetting.content == null) {
      return {
        name: 'Not Available',
        value: 'N/A'
      };
    } else {
      return {
        name: this.convertContentValueToName(this.beaconSetting.content),
        value: '0x' + this.beaconSetting.content.toString(16).toUpperCase()
      };
    }
  }

  public get beaconInterval(): { name: string; value: string } {
    if (this.beaconSetting == null || this.beaconSetting.beaconInterval == null) {
      return {
        name: 'Not Available',
        value: 'N/A'
      };
    } else {
      return {
        name: this.beaconSetting.beaconInterval + ' ms',
        value: this.convertIntervalValueToLabel(this.beaconSetting.beaconInterval)
      };
    }
  }

  public get beaconUpdateStatus(): string {
    if (this.beaconSetting == null || this.beaconSetting.updateStatus == null) {
      return 'N/A';
    } else {
      return this.beaconSetting.updateStatus;
    }
  }

  public get beaconUUID(): { name: string; value: string } {
    if (this.beaconSetting == null || this.beaconSetting.uuid == null) {
      return {
        name: 'Not Available',
        value: 'N/A'
      };
    } else {
      return {
        name: this.convertUUIDValueToName(this.beaconSetting.uuid),
        value: this.beaconSetting.uuid
      };
    }
  }

  public get lightingConfigurationZone(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.zone == null) {
      return 'N/A';
    } else {
      return '' + (this.lightingConfiguration.zone !== 15 ? this.lightingConfiguration.zone + 1 : 'X');
    }
  }

  private convertContentValueToName(value: number): string {
    let name = '0x' + value.toString(16).toUpperCase();
    BeaconSetting.contents.forEach((item) => {
      if (item.value === value) {
        name = item.name;
      }
    });
    return name;
  }

  private convertIntervalValueToLabel(value: number): string {
    let name = '0x' + value.toString(16).toUpperCase();
    BeaconSetting.intervals.forEach((item) => {
      if (item.value === value) {
        name = item.name;
      }
    });
    return name;
  }

  private convertUUIDValueToName(value: string): string {
    let name = value;
    BeaconSetting.uuids.forEach((item) => {
      if (item.value === value) {
        name = item.name;
      }
    });
    return name;
  }

  get alerts(): SensorNodeAlert[] {
    const alerts: SensorNodeAlert[] = [];

    if (!this.connected && this.properlyMapped) {
      alerts.push(new SensorNodeAlert('Sensor node disconnected'));
    }

    this.luminaireDrivers?.forEach((driver) => {
      driver.daliStates?.forEach((state) =>
        alerts.push(
          new SensorNodeAlert(`Dali Driver address: ${driver.address16} - ${StringUtils.humanizeConstant(state)}`)
        )
      );
    });

    this.emDrivers?.forEach((driver) => {
      driver.emergencyLightingFailureStates?.forEach((state) =>
        alerts.push(
          new SensorNodeAlert(`EM Driver address: ${driver.address16} - ${StringUtils.humanizeConstant(state)}`)
        )
      );
    });

    this.duplicateAddressMappings?.forEach((dam) => {
      const duplicateAddressMapping = new DuplicateMapping(
        dam.nodeId,
        dam.floorId,
        dam.buildingId,
        dam.value,
        DuplicateType.ADDRESS
      );
      alerts.push(
        new SensorNodeAlert(
          `Duplicate Mapping Node Id ${duplicateAddressMapping.nodeId}`,
          duplicateAddressMapping.getLink()
        )
      );
    });

    this.duplicateGroupMappings?.forEach((dgm) => {
      const duplicateGroupMapping = new DuplicateMapping(
        dgm.nodeId,
        dgm.floorId,
        dgm.buildingId,
        dgm.value,
        DuplicateType.GROUP
      );
      alerts.push(
        new SensorNodeAlert(
          `Duplicate Grouping (${duplicateGroupMapping.value}) Node Id ${duplicateGroupMapping.nodeId}`,
          duplicateGroupMapping.getLink()
        )
      );
    });

    return alerts;
  }

  public get status(): SensorNodeStatus {
    if (!this.properlyMapped) {
      return SensorNodeStatus.UNKNOWN;
    }

    if (!this.connected) {
      return SensorNodeStatus.DISCONNECTED;
    }

    if (this.isFaulty) {
      return SensorNodeStatus.FAULTY;
    }

    return SensorNodeStatus.OK;
  }

  public get isFaulty(): boolean {
    return (
      this.luminaireDrivers?.some((driver) => driver.daliStates != null && driver.daliStates.length > 0) ||
      this.emDrivers?.some(
        (driver) => driver.emergencyLightingFailureStates != null && driver.emergencyLightingFailureStates.length > 0
      )
    );
  }

  static from(dto: SensorNodeDTO): SensorNode {
    return new SensorNode({ ...dto });
  }

  static fromPosition(position: { x: number; y: number }): SensorNode {
    return new SensorNode({
      address: null,
      bleKey: null,
      connected: false,
      duplicateAddressMappings: [],
      duplicateGroupMappings: [],
      emDrivers: undefined,
      floorId: null,
      groupId: null,
      installedAt: undefined,
      isChanged: false,
      isDriverEmergencyLightingTestStarted: false,
      isTooManyDriverEmergencyLightingTest: false,
      luminaireDrivers: undefined,
      nodeType: 'SN3',
      properlyMapped: false,
      scene: null,
      subscriber: false,
      tags: [],
      tenants: [],
      timeSinceInstall: null,
      updatedAt: undefined,
      x: position.x,
      y: position.y,
      hasMappingBeenAttempted: false,
      bleScanning: null,
      isNotifying: false,
      isFaulty: false,
      beaconSetting: undefined,
      lightingConfiguration: undefined
    });
  }

  update(newX: number, newY: number): void {
    this.x = newX;
    this.y = newY;
    this.isChanged = true;
  }

  get address16(): string {
    return this.properlyMapped ? this.address.toString(16).toUpperCase() : 'Unmapped';
  }
}
