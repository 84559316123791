<div>
  <li class="or-inline-item">
    <button class='or-button' mat-raised-button matTooltip="Reset" (click)="reset()" [disabled]="disable">
      <span class='or-icon or-small or-icon-reload'></span>
<!--      <span class='or-button-label text-sm'>Reset</span>-->
    </button>
  </li>
  <li class="or-inline-item">
    <button class='or-button' [matTooltip]="submitLabel === 'Generate' ? 'Generate' : 'Update Settings'" color="primary" (click)='update()' mat-raised-button  data-cy="generate">
      <span class='or-icon or-small or-inverse or-icon-plus'></span>
<!--      <span class="or-button-label text-sm">{{ submitLabel }}</span>-->
    </button>
  </li>
</div>
