import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BacnetAreaService } from '@services/bacnet-area.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { concatMap, Observable } from 'rxjs';
import { NavigationService } from '@services/navigation/navigation.service';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@services/user/user.service';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { BacnetArea, GatewayOption } from '@app/shared/models/bacnet-area.interface';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-bacnet-area-tile',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedComponentsModule],
  templateUrl: './bacnet-area-tile.component.html',
  styleUrl: './bacnet-area-tile.component.scss'
})
export class BacnetAreaTileComponent implements OnInit {
  @Input() enableLabels: boolean;
  @Input() isNew: boolean;
  @Input() gateways: Gateway[] = [];
  @Input() area: BacnetArea;
  @Output() valueChanged = new EventEmitter<void>();

  building$: Observable<Building>;
  gatewayOptions: GatewayOption[];
  originalArea: BacnetArea;
  bacnetForm: FormGroup;

  private isLoadingDetails = false;
  private isDeletingArea = false;
  private isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private bacnetAreaService: BacnetAreaService,
    private navigationService: NavigationService,
    private userService: UserService,
    private confirmDialogService: ConfirmationDialogService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.gatewayOptions = this.produceGatewayOptions(this.gateways);
    this.setBuildingId();
    this.setAreaDetails();
  }

  setBuildingId(): void {
    this.building$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return this.userService.getBuilding(buildingId);
      })
    );
    this.building$.subscribe((building) => {
      this.navigationService.initNavigation(window.location.href, building);
    });
  }

  discardChanges(): void {
    const dialogData = new ConfirmDialogData(
      this.isNew ? `Discard changes for the new area?` : `Discard changes for ${this.area.name}?`
    );

    this.confirmDialogService.open(dialogData).subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (!this.isNew) {
          this.bacnetForm.reset({
            areaId: this.originalArea.localId,
            areaName: this.originalArea.name,
            areaDescription: this.originalArea.description,
            gatewayId: this.originalArea.gatewayId,
            portalId: this.originalArea.id
          });
        } else {
          this.bacnetForm.reset();
        }

        this.resetForm();
      }
    });
  }

  deleteArea(): void {
    const dialogData = new ConfirmDialogData(
      `Are you sure you want to delete the area ${this.area.name}?`,
      'Deleting Area'
    );

    this.confirmDialogService.open(dialogData).subscribe((confirmed) => {
      if (confirmed) {
        this.isDeletingArea = true;
        this.bacnetAreaService.deleteArea(this.area.id).subscribe(() => {
          this.isDeletingArea = false;
          this.resetForm();
          this.valueChanged.emit();
        });
      }
    });
  }

  submit(): void {
    if (this.isNew) {
      this.addArea();
      return;
    }

    this.updateArea();
  }

  isBusy(): boolean {
    return this.isLoadingDetails || this.isDeletingArea || this.isSubmitting;
  }

  private resetForm(): void {
    this.bacnetForm.markAsUntouched();
    this.bacnetForm.markAsPristine();
  }

  private addArea(): void {
    if (this.bacnetForm.get('areaName').value === null || this.bacnetForm.get('gatewayId').value === null) {
      alert('Both Name and Gateway fields need values.');
      return;
    }
    const area: BacnetArea = {
      gatewayId: this.bacnetForm.get('gatewayId').value,
      description: this.bacnetForm.get('areaDescription').value,
      name: this.bacnetForm.get('areaName').value
    };

    this.isSubmitting = true;
    this.bacnetAreaService.addArea(area).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.bacnetForm.reset();
        this.resetForm();
        this.valueChanged.emit();
      },
      error: () => {
        alert('An error happened when trying to add the new area.');
        this.isSubmitting = false;
        this.resetForm();
      }
    });

    this.area = { ...this.originalArea };
  }

  private updateArea(): void {
    if (this.bacnetForm.get('areaName').value === null || this.bacnetForm.get('gatewayId').value === null) {
      alert('Both Name and Gateway fields need values.');
      return;
    }

    const dialogData = new ConfirmDialogData(
      `Are you sure you want to update the area ${this.area.name} with the new values?`,
      'Updating Area'
    );

    this.confirmDialogService.open(dialogData).subscribe((confirmed) => {
      if (confirmed) {
        this.isSubmitting = true;
        this.originalArea = { ...this.area };

        const areaToUpdate: BacnetArea = {
          id: this.area.id,
          gatewayId: this.bacnetForm.get('gatewayId').value,
          description: this.bacnetForm.get('areaDescription').value,
          name: this.bacnetForm.get('areaName').value
        };

        this.bacnetAreaService.updateArea(areaToUpdate).subscribe(() => {
          this.isSubmitting = false;
          this.resetForm();
          this.valueChanged.emit();
        });
      }
    });
  }

  private produceGatewayOptions(gateways: Gateway[]): { id: number; label: string }[] {
    return gateways.map((gateway) => {
      return {
        id: gateway.id,
        label: gateway.address + '  [ ' + (gateway.name ? gateway.name : ' N/A ') + ' ]'
      };
    });
  }

  private setAreaDetails(): void {
    if (this.isNew) {
      this.area = null;
    }
    this.originalArea = { ...this.area };
    this.setForm();
  }

  private setForm(): void {
    this.bacnetForm = this.fb.group({
      id: [{ value: this.area?.id, disabled: true }],
      areaId: [{ value: this.area?.localId, disabled: true }, Validators.required],
      areaName: [this.area?.name, Validators.required],
      areaDescription: [this.area?.description, Validators.required],
      gatewayId: [this.area?.gatewayId, Validators.required],
      portalId: [{ value: null, disabled: true }, Validators.required]
    });
  }
}
