import { IOptionalRefreshLocationService } from '@angularjs/or/angular/IOptionalRefreshLocationService';
import { AuthMethod } from '@app/shared/models/auth-method';
import { Building } from '@angularjs/or/api/building/Building';
import { Floor } from '@angularjs/or/api/building/Floor';
import { QueryExecutorConfiguration } from '@angularjs/or/api/query/IQueryExecutor';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { ANGULAR_JS_MODULE_NAME } from '@common/app-constants';
import * as Routes from '@common/route-constants';
import * as ng from 'angular';
import 'angular-animate';
import 'angular-route';
import 'angularjs-datepicker';
import 'd3';
import 'ng-dialog';
import 'ngtouch';
import { PageModuleConfig } from '../../modules/layout/all';
import { HttpInterceptor } from './http-interceptor';
import {
  Administration,
  Beacons,
  // Analytics,
  Analytics,
  BuildingAdministration,
  Buildings,
  CircadianCurves,
  Dashboard,
  DdlAdministration,
  EmergencyLighting,
  EmergencyLightingAdmin,
  EmergencyLightingLogs,
  EmergencyLightingManualTests,
  EmergencyLightingReports,
  EmergencyLightingSchedule,
  FloorAdministration,
  GatewaysAdministration,
  Integrations,
  MapNodes,
  MobileTokensAdministration,
  NewBuilding,
  RolesAdministration,
  SensorNodes,
  TenantGroupsAdministration,
  TokensAdministration,
  UsersAdministration
} from './migrated-routes.config';
import { PortalConfig } from './PortalConfig';
import { LightingConfigurationConfig } from './routes/lighting-configuration/LightingConfigurationConfig';
import { LoginConfig } from './routes/login/LoginConfig';
import { SchedulesConfig } from './routes/schedules/SchedulesConfig';
import { UserConfig } from './routes/user/UserConfig';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { ConfigService } from '@app/config-service';
import { FeatureService } from '@app/shared/services/feature.service';
import { PassiveNodeService } from '@app/shared/services/passive-node.service';
import { UserService } from '@services/user/user.service';
import { TagsComponent } from '@components/tags/tags.component';
import { TenantService } from '@services/building/tenant.service';
import { NodeListComponent } from '@components/node-list/node-list.component';
import { FloorplanActionsComponent } from '@components/floorplan/floorplan-actions/floorplan-actions.component';
import IScope = ng.IScope;
import IRouteService = ng.route.IRouteService;
import { AnalyticsConfig } from '@angularjs/angular/app/portal/routes/analytics/AnalyticsConfig';
import { MetricSelectorComponent } from '@app/analytics/metric-selector/metric-selector.component';

PageModuleConfig.configure();

const portalConfig = new PortalConfig();
const apiUrl = (path: string) => {
  return portalConfig.environment().apiUrl + path;
};

ng.module(ANGULAR_JS_MODULE_NAME, ['ngRoute', 'ngDialog', 'orLayout', 'ngTouch', '720kb.datepicker'])
  .directive('appTags', downgradeComponent({ component: TagsComponent }) as ng.IDirectiveFactory)
  .directive('appNodeList', downgradeComponent({ component: NodeListComponent }) as ng.IDirectiveFactory)
  .directive(
    'appFloorplanActions',
    downgradeComponent({ component: FloorplanActionsComponent }) as ng.IDirectiveFactory
  )
  .directive('appMetricSelector', downgradeComponent({ component: MetricSelectorComponent }) as ng.IDirectiveFactory)
  .config([
    '$httpProvider',
    function ($httpProvider: ng.IHttpProvider) {
      $httpProvider.interceptors.push(HttpInterceptor.Factory);
      $httpProvider.xsrfTrustedOrigins.push(portalConfig.environment().apiUrl);
    }
  ])
  .config(['$locationProvider', portalConfig.locationProvider])
  .config(['$routeProvider', portalConfig.routeProvider])
  .factory('NavigationService', downgradeInjectable(NavigationService))
  .factory('ConfigurationService', downgradeInjectable(ConfigService))
  .factory('FeatureService', downgradeInjectable(FeatureService))
  .factory('PassiveNodeService', downgradeInjectable(PassiveNodeService))
  .factory('NewUserService', downgradeInjectable(UserService))
  .factory('TenantService', downgradeInjectable(TenantService))
  .run(['NavigationService', 'MainNavigationInfo', 'DropdownService', portalConfig.run])
  .run([
    '$route',
    '$rootScope',
    '$location',
    function ($route: IRouteService, $scope: IScope, $location: IOptionalRefreshLocationService) {
      $scope.$on('$routeChangeSuccess', function (e, current, previous) {
        if (previous && previous.params.admin == true) {
          $location.performSearch('admin', true, false);
        }
      });

      $location.performSearch = (search: string, paramValue: string | number | string[] | boolean, reload = true) => {
        if (!reload) {
          const lastRoute = $route.current;
          const unregister = $scope.$on(
            '$locationChangeSuccess',
            (event: ng.IAngularEvent, before: string, after: string) => {
              const regex = new RegExp('^[^?]+');
              const afterPath = regex.exec(after)[0];
              const beforePath = regex.exec(before)[0];
              if (afterPath === beforePath) {
                $route.current = lastRoute;
              }
              unregister();
            }
          );
        }

        return $location.search(search, paramValue);
      };
    }
  ])

  .value(
    'MainNavigationInfo',
    new NavigationSectionInfo(
      Routes.MAIN_ROUTE.id,
      Routes.MAIN_ROUTE.name,
      Routes.MAIN_ROUTE.path,
      Routes.MAIN_ROUTE.regex
    )
  )
  .value(
    'AdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.ADMIN_ROUTE.id,
      Routes.ADMIN_ROUTE.name,
      Routes.ADMIN_ROUTE.path,
      Routes.ADMIN_ROUTE.regex,
      Routes.ADMIN_ROUTE.icon
    )
  )
  .value(
    'BuildingAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.BUILDING_ADMIN_ROUTE.id,
      Routes.BUILDING_ADMIN_ROUTE.name,
      Routes.BUILDING_ADMIN_ROUTE.path,
      Routes.BUILDING_ADMIN_ROUTE.regex
    )
  )
  .value(
    'FloorsAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.FLOORS_ADMIN_ROUTE.id,
      Routes.FLOORS_ADMIN_ROUTE.name,
      Routes.FLOORS_ADMIN_ROUTE.path,
      Routes.FLOORS_ADMIN_ROUTE.regex
    )
  )
  .value(
    'GatewaysAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.GATEWAYS_ADMIN_ROUTE.id,
      Routes.GATEWAYS_ADMIN_ROUTE.name,
      Routes.GATEWAYS_ADMIN_ROUTE.path,
      Routes.GATEWAYS_ADMIN_ROUTE.regex
    )
  )
  .value(
    'UsersAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.USERS_ADMIN_ROUTE.id,
      Routes.USERS_ADMIN_ROUTE.name,
      Routes.USERS_ADMIN_ROUTE.path,
      Routes.USERS_ADMIN_ROUTE.regex
    )
  )
  .value(
    'RolesAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.ROLES_ADMIN_ROUTE.id,
      Routes.ROLES_ADMIN_ROUTE.name,
      Routes.ROLES_ADMIN_ROUTE.path,
      Routes.ROLES_ADMIN_ROUTE.regex
    )
  )
  .value(
    'TokensAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.TOKENS_ADMIN_ROUTE.id,
      Routes.TOKENS_ADMIN_ROUTE.name,
      Routes.TOKENS_ADMIN_ROUTE.path,
      Routes.TOKENS_ADMIN_ROUTE.regex
    )
  )
  .value(
    'MobileTokensAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.MOBILE_TOKENS_ADMIN_ROUTE.id,
      Routes.MOBILE_TOKENS_ADMIN_ROUTE.name,
      Routes.MOBILE_TOKENS_ADMIN_ROUTE.path,
      Routes.MOBILE_TOKENS_ADMIN_ROUTE.regex
    )
  )
  .value(
    'TenantGroupsAdministrationNavigationInfo',
    new NavigationSectionInfo(
      Routes.TENANT_GROUPS_ADMIN_ROUTE.id,
      Routes.TENANT_GROUPS_ADMIN_ROUTE.name,
      Routes.TENANT_GROUPS_ADMIN_ROUTE.path,
      Routes.TENANT_GROUPS_ADMIN_ROUTE.regex
    )
  )
  .value(
    'HeatmapNavigationInfo',
    new NavigationSectionInfo(
      Routes.HEATMAP_ROUTE.id,
      Routes.HEATMAP_ROUTE.name,
      Routes.HEATMAP_ROUTE.path,
      Routes.HEATMAP_ROUTE.regex,
      Routes.HEATMAP_ROUTE.icon
    )
  )
  .value(
    'BuildingsNavigationInfo',
    new NavigationSectionInfo(
      Routes.BUILDINGS_ROUTE.id,
      Routes.BUILDINGS_ROUTE.name,
      Routes.BUILDINGS_ROUTE.path,
      Routes.BUILDINGS_ROUTE.regex
    )
  )
  .value(
    'LoginNavigationInfo',
    new NavigationSectionInfo(
      Routes.LOGIN_ROUTE.id,
      Routes.LOGIN_ROUTE.name,
      Routes.LOGIN_ROUTE.path,
      Routes.LOGIN_ROUTE.regex
    )
  )
  .value(
    'UserNavigationInfo',
    new NavigationSectionInfo(
      Routes.USER_ROUTE.id,
      Routes.USER_ROUTE.name,
      Routes.USER_ROUTE.path,
      Routes.USER_ROUTE.regex
    )
  )
  .value(
    'BuildingsNavigationInfo',
    new NavigationSectionInfo(
      Routes.BUILDINGS_ROUTE.id,
      Routes.BUILDINGS_ROUTE.name,
      Routes.BUILDINGS_ROUTE.path,
      Routes.BUILDINGS_ROUTE.regex
    )
  )
  .value(
    'NewBuildingNavigationInfo',
    new NavigationSectionInfo(
      Routes.NEW_BUILDING_ROUTE.id,
      Routes.NEW_BUILDING_ROUTE.name,
      Routes.NEW_BUILDING_ROUTE.path,
      Routes.NEW_BUILDING_ROUTE.regex
    )
  )
  .value(
    'MapNodesNavigationInfo',
    new NavigationSectionInfo(
      Routes.MAP_NODES_ROUTE.id,
      Routes.MAP_NODES_ROUTE.name,
      Routes.MAP_NODES_ROUTE.path,
      Routes.MAP_NODES_ROUTE.regex,
      Routes.MAP_NODES_ROUTE.icon
    )
  )
  .value(
    'SensorNodesNavigationInfo',
    new NavigationSectionInfo(
      Routes.SENSORNODES_ROUTE.id,
      Routes.SENSORNODES_ROUTE.name,
      Routes.SENSORNODES_ROUTE.path,
      Routes.SENSORNODES_ROUTE.regex,
      Routes.SENSORNODES_ROUTE.icon
    )
  )
  .value(
    'DashboardNavigationInfo',
    new NavigationSectionInfo(
      Routes.DASHBOARD_ROUTE.id,
      Routes.DASHBOARD_ROUTE.name,
      Routes.DASHBOARD_ROUTE.path,
      Routes.DASHBOARD_ROUTE.regex,
      Routes.DASHBOARD_ROUTE.icon
    )
  )
  .value(
    'SchedulesNavigationInfo',
    new NavigationSectionInfo(
      Routes.SCHEDULES_ROUTE.id,
      Routes.SCHEDULES_ROUTE.name,
      Routes.SCHEDULES_ROUTE.path,
      Routes.SCHEDULES_ROUTE.regex,
      Routes.SCHEDULES_ROUTE.icon
    )
  )
  .value(
    'CircadianCurvesNavigationInfo',
    new NavigationSectionInfo(
      Routes.CIRCADIAN_CURVES_ROUTE.id,
      Routes.CIRCADIAN_CURVES_ROUTE.name,
      Routes.CIRCADIAN_CURVES_ROUTE.path,
      Routes.CIRCADIAN_CURVES_ROUTE.regex,
      Routes.CIRCADIAN_CURVES_ROUTE.icon
    )
  )
  .value(
    'EmergencyLightingNavigationInfo',
    new NavigationSectionInfo(
      Routes.EMERGENCY_LIGHTING_BASE_ROUTE.id,
      Routes.EMERGENCY_LIGHTING_BASE_ROUTE.name,
      Routes.EMERGENCY_LIGHTING_BASE_ROUTE.path,
      Routes.EMERGENCY_LIGHTING_BASE_ROUTE.regex,
      Routes.EMERGENCY_LIGHTING_BASE_ROUTE.icon
    )
  )
  .value(
    'EmergencyLightingScheduleNavigationInfo',
    new NavigationSectionInfo(
      Routes.EMERGENCY_LIGHTING_SCHEDULES_ROUTE.id,
      Routes.EMERGENCY_LIGHTING_SCHEDULES_ROUTE.name,
      Routes.EMERGENCY_LIGHTING_SCHEDULES_ROUTE.path,
      Routes.EMERGENCY_LIGHTING_SCHEDULES_ROUTE.regex
    )
  )
  .value(
    'EmergencyLightingLogsNavigationInfo',
    new NavigationSectionInfo(
      Routes.EMERGENCY_LIGHTING_LOGS_ROUTE.id,
      Routes.EMERGENCY_LIGHTING_LOGS_ROUTE.name,
      Routes.EMERGENCY_LIGHTING_LOGS_ROUTE.path,
      Routes.EMERGENCY_LIGHTING_LOGS_ROUTE.regex
    )
  )
  .value(
    'EmergencyLightingReportsNavigationInfo',
    new NavigationSectionInfo(
      Routes.EMERGENCY_LIGHTING_REPORTS_ROUTE.id,
      Routes.EMERGENCY_LIGHTING_REPORTS_ROUTE.name,
      Routes.EMERGENCY_LIGHTING_REPORTS_ROUTE.path,
      Routes.EMERGENCY_LIGHTING_REPORTS_ROUTE.regex
    )
  )
  .value(
    'EmergencyLightingManualTestNavigationInfo',
    new NavigationSectionInfo(
      Routes.EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.id,
      Routes.EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.name,
      Routes.EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.path,
      Routes.EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.regex
    )
  )
  .value(
    'EmergencyLightingAdminNavigationInfo',
    new NavigationSectionInfo(
      Routes.EMERGENCY_LIGHTING_ADMIN_ROUTE.id,
      Routes.EMERGENCY_LIGHTING_ADMIN_ROUTE.name,
      Routes.EMERGENCY_LIGHTING_ADMIN_ROUTE.path,
      Routes.EMERGENCY_LIGHTING_ADMIN_ROUTE.regex
    )
  )
  .value(
    'IntegrationsNavigationInfo',
    new NavigationSectionInfo(
      Routes.INTEGRATIONS_ROUTE.id,
      Routes.INTEGRATIONS_ROUTE.name,
      Routes.INTEGRATIONS_ROUTE.path,
      Routes.INTEGRATIONS_ROUTE.regex,
      Routes.INTEGRATIONS_ROUTE.icon
    )
  )
  .value(
    'BeaconsNavigationInfo',
    new NavigationSectionInfo(
      Routes.BEACONS_ROUTE.id,
      Routes.BEACONS_ROUTE.name,
      Routes.BEACONS_ROUTE.path,
      Routes.BEACONS_ROUTE.regex,
      Routes.BEACONS_ROUTE.icon
    )
  )
  .value(
    'LightingConfigurationNavigationInfo',
    new NavigationSectionInfo(
      Routes.LIGHTING_CONFIGURATION_ROUTE.id,
      Routes.LIGHTING_CONFIGURATION_ROUTE.name,
      Routes.LIGHTING_CONFIGURATION_ROUTE.path,
      Routes.LIGHTING_CONFIGURATION_ROUTE.regex,
      Routes.LIGHTING_CONFIGURATION_ROUTE.icon
    )
  )
  .value(
    'IntegrationsAreasNavigationInfo',
    new NavigationSectionInfo(
      Routes.INTEGRATIONS_AREAS_ROUTE.id,
      Routes.INTEGRATIONS_AREAS_ROUTE.name,
      Routes.INTEGRATIONS_AREAS_ROUTE.path,
      Routes.INTEGRATIONS_AREAS_ROUTE.regex
    )
  )
  .value(
    'DDLAdministrationNavigationInfo',
    new NavigationSectionInfo(Routes.DDL_ROUTE.id, Routes.DDL_ROUTE.name, Routes.DDL_ROUTE.path, Routes.DDL_ROUTE.regex)
  )

  .value(
    'QueryExecutorConfiguration',
    new QueryExecutorConfiguration(
      apiUrl(`/api/v1/query/simple`),
      apiUrl(`/api/v1/query`),
      apiUrl(`/api/v1/query/live`),
      apiUrl(`/api/v1/query/live/node-data`)
    )
  )
  .value('BaseUrl', 'http://' + window.location.host)
  .value('LoginMethodsBaseUrl', apiUrl('/auth/logins'))
  .factory('AuthenticateBaseUrl', [
    'BaseUrl',
    function (baseUrl) {
      return (authMethod: AuthMethod, apiUrlTarget?: string) =>
        apiUrl(
          '/auth/' + authMethod.toString().toLowerCase() + '?target=' + (apiUrlTarget ? apiUrl(apiUrlTarget) : baseUrl)
        );
    }
  ])
  .value('GenerateApiUrl', apiUrl)
  .value('EnvBaseUrl', apiUrl('/api/v1/env'))
  .value('LogoutBaseUrl', apiUrl('/auth/perform_logout'))
  .value('PostLogoutRedirectBaseUrl', 'http://organicresponse.com/')
  .value('RolesResourceBaseUrl', apiUrl('/api/v1/user-roles/me/'))
  .value('RoleResourceBaseUrl', apiUrl('/api/v1/role'))
  .value('UserResourceBaseUrl', apiUrl('/api/v1/user'))
  .value('UserRoleResourceBaseUrl', apiUrl('/api/v1/user-roles'))
  .value('RoleResourceBaseUrl', apiUrl('/api/v1/role'))
  .value('BuildingResourceBaseUrl', apiUrl('/api/v1/building'))
  .value('FloorResourceBaseUrl', apiUrl('/api/v1/floor'))
  .value('GatewayResourceBaseUrl', apiUrl('/api/v1/gateway'))
  .value('DdlSettingResourceBaseUrl', apiUrl('/api/v1/ddl-building-setting'))
  .value('TokenResourceBaseUrl', apiUrl('/api/v1/token'))
  .value('MobileTokenResourceBaseUrl', apiUrl('/api/v1/mobile-token'))
  .value('BleKeyResourceBaseUrl', apiUrl('/api/v1/ble-key'))
  .value('BeaconSettingResourceBaseUrl', apiUrl('/api/v1/beacon-setting'))
  .value('LightingConfigurationResourceBaseUrl', apiUrl('/api/v1/lighting-config/floor'))
  .value('NodeResourceBaseUrl', apiUrl('/api/v1/node'))
  .value('EmDriverResourceBaseUrl', apiUrl('/api/v1/em-driver'))
  .value('LuminaireDriverResourceBaseUrl', apiUrl('/api/v1/luminaire-driver'))
  .value('TagResourceBaseUrl', apiUrl('/api/v1/tag'))
  .value('MultiTagResourceBaseUrl', apiUrl('/api/v1/nodes/tags'))
  .value('MappingResourceBaseUrl', apiUrl('/api/v1/map'))
  .value('UserInvitationResourceBaseUrl', apiUrl('/auth/invite/multi'))
  .value('ScheduleResourceBaseUrl', apiUrl('/api/v1/schedule'))
  .value('EmergencyLightingResourceBaseUrl', apiUrl('/api/v1/emergency_lighting'))
  .value('EmergencyLightingTestResultsResourceBaseUrl', apiUrl('/api/v1/emergency-lighting-test-results'))
  .value('BuildingNotificationBaseUrl', apiUrl('/api/v1/buildingnotification'))
  .value('LuminaireManufacturerBaseUrl', apiUrl('/api/v1/luminaire-manufacturer'))
  .value('SensorNodeBleResourceBaseUrl', apiUrl('/api/v1/node-config'))
  .value('ErrorPageBaseUrl', apiUrl('/error'))
  .value('UserInfoBaseUrl', '/user')
  .value('DuplicateMappingResourceBaseUrl', apiUrl('/api/v1/duplicate-mapping'))
  .value('GlobalAdministrationBaseUrl', '/global-administration')
  .value('Debug', false)
  .value('QueryCacheTtl', 30000)

  .service('ErrorHandler', [
    '$window',
    '$location',
    '$routeParams',
    'ErrorPageBaseUrl',
    'BaseUrl',
    function ($window, $location, $routeParams, errorPageUrl, baseUrl) {
      let isAlreadyHandlingError = false;

      return (reason) => {
        // FIXME: Ideally we should redirect to error page on 400 as well, but with how some requests work currently
        // FIXME: creating new building for eg, which require floor id to be null for correct UX, we might have to
        // FIXME: with a better strategy to handle 400s
        // FIXME: Need to do the same for 500s
        const isHandlingRequired = reason == 401 || reason == 404;
        const urlToEncode = $location.url();
        const toIgnore = new RegExp('^/assets/');

        if (isHandlingRequired && !isAlreadyHandlingError && !toIgnore.test(urlToEncode)) {
          let newUrl;
          let absolutePath = false;
          switch (reason) {
            // case 400:
            //     newUrl = errorPageUrl + '/400.html?base=' + baseUrl;
            //     absolutePath = true;
            //     break;
            case 401:
              newUrl = handle401($routeParams, urlToEncode);
              absolutePath = false;
              break;
            case 404:
              newUrl = errorPageUrl + '/404.html?base=' + baseUrl;
              absolutePath = true;
              break;
            // case 500:
            //     newUrl = errorPageUrl + '/500.html?base=' + baseUrl;
            //     absolutePath = true;
            //     break;
          }
          if (absolutePath) {
            $window.location = newUrl;
          } else {
            $location.url(newUrl);
          }
          console.log('ErrorHandler is setting the location.url', newUrl);

          isAlreadyHandlingError = true;
        }
        return isHandlingRequired;
      };
    }
  ])

  .value('MappingAudioUrlGenerator', (id) => {
    return '/assets/static/mapping/' + id + '.wav';
  })
  .value('FloorplanUrlGenerator', (floor: Floor) => {
    return floor && floor.floorplan ? apiUrl('/api/v1/floor/' + floor.id + '/floorplan?id=' + floor.floorplan) : null;
  })
  .value('ThumbnailUrlGenerator', (building: Building) => {
    return building && building.thumbnailImage
      ? apiUrl('/api/v1/building/' + building.id + '/thumbnail?id=' + building.thumbnailImage)
      : null;
  })
  .value('AddNodesUrlGenerator', (floorId) => {
    return floorId ? apiUrl('/api/v1/node/floor/' + floorId) : null;
  })
  .value('BuildingUrlGenerator', (buildingId, floorId) => {
    return '/buildings/' + buildingId + '/' + (floorId || null) + '/heatmap';
  })
  .value('SensorNodesUrlGenerator', (buildingId, floorId) => {
    return '/buildings/' + buildingId + '/' + (floorId || null) + '/nodes';
  })
  .value('DashboardUrlGenerator', (buildingId) => {
    return '/buildings/' + buildingId + '/dashboard';
  })
  .value('MapNodesUrlGenerator', (buildingId, floorId) => {
    return `/buildings/${buildingId}/${floorId || null}/map-nodes`;
  })
  .value('SensorNodesUrlGenerator', (buildingId, floorId) => {
    return `/buildings/${buildingId}/${floorId || null}/sensor-nodes`;
  })
  .value('BeaconsUrlGenerator', (buildingId, floorId) => {
    return '/buildings/' + buildingId + '/' + (floorId || null) + '/beacons';
  })
  .value('LightingConfigurationUrlGenerator', (buildingId, floorId) => {
    return '/buildings/' + buildingId + '/' + (floorId || null) + '/lighting-configuration';
  })
  .value('AdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration' : null;
  })
  .value('BuildingAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/building' : null;
  })
  .value('FloorAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/floors' : null;
  })
  .value('GatewaysAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/gateways' : null;
  })
  .value('UsersAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/users' : null;
  })
  .value('RoleAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/roles' : null;
  })
  .value('TokenAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/tokens' : null;
  })
  .value('MobileTokenAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/mobile-tokens' : null;
  })
  .value('TenantGroupsAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/tenant-groups' : null;
  })
  .value('DDLAdministrationUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/ddl' : null;
  })
  .value('SchedulesUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/schedules' : null;
  })
  .value('CircadianCurvesUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/circadian-curves' : null;
  })
  .value('EmergencyLightingAdminUrlGenerator', (buildingId) => {
    return buildingId ? '/buildings/' + buildingId + '/administration/emergency-lighting' : null;
  })
  .value('EmergencyLightingUrlSchedulesGenerator', (buildingId) => {
    if (!buildingId) {
      return;
    }
    return `/buildings/${buildingId}/emergency-lighting/schedules`;
  })
  .value('EmergencyLightingUrlLogsGenerator', (buildingId) => {
    if (!buildingId) {
      return;
    }
    return `/buildings/${buildingId}/emergency-lighting/logs`;
  })
  .value('EmergencyLightingUrlReportsGenerator', (buildingId) => {
    if (!buildingId) {
      return;
    }
    return `/buildings/${buildingId}/emergency-lighting/reports`;
  })
  .value('EmergencyLightingUrlManualTestsGenerator', (buildingId, floorId) => {
    if (!buildingId) {
      return;
    }
    if (!floorId) {
      floorId = null;
    }
    return `/buildings/${buildingId}/emergency-lighting/${floorId}/manual-tests`;
  })
  .value('IntegrationsUrlGenerator', (buildingId, floorId) => {
    if (!buildingId) {
      return;
    }
    if (!floorId) {
      floorId = null;
    }
    return `/buildings/${buildingId}/${floorId}/integrations`;
  })
  .value('IntegrationsAreasUrlGenerator', (buildingId, floorId) => {
    if (!buildingId) {
      return;
    }
    if (!floorId) {
      floorId = null;
    }
    return `/buildings/${buildingId}/${floorId}/integrations/areas`;
  })
  .value('LampTypeResourceUrlGenerator', (buildingId) => {
    return buildingId ? apiUrl('/api/v1/lamp_type/' + buildingId) : null;
  })
  .value('BuildingsUrlGenerator', () => {
    return '/buildings';
  })
  .value('NewBuildingUrlGenerator', () => {
    return '/buildings/new';
  });

function handle401($routeParams, urlToEncode): string {
  let alreadyHandledAuthError = false;
  if ($routeParams.attemptAuthorization) {
    alreadyHandledAuthError = true;
  } else {
    if (urlToEncode.indexOf('?') == -1) {
      urlToEncode += '?attemptAuthorization=true';
    } else {
      urlToEncode += '&attemptAuthorization=true';
    }
  }
  return (
    '/login?redirectUrl=' +
    encodeURIComponent(urlToEncode) +
    (alreadyHandledAuthError ? '&alreadyHandledAuthError=true' : '')
  );
}

LoginConfig.configure(Routes.LOGIN_ROUTE.path);
UserConfig.configure(Routes.USER_ROUTE.path);
// BuildingsConfig.configure(Routes.BUILDINGS_ROUTE.path);
Buildings.config();
NewBuilding.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
Dashboard.config();
Analytics.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
// AnalyticsConfig.configure(Routes.HEATMAP_ROUTE.path);
SensorNodes.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
SchedulesConfig.configure(Routes.SCHEDULES_ROUTE.path);
EmergencyLighting.config();
EmergencyLightingSchedule.config();
EmergencyLightingLogs.config();
EmergencyLightingReports.config();
EmergencyLightingManualTests.config();
Beacons.config();
Integrations.config();
LightingConfigurationConfig.configure(Routes.LIGHTING_CONFIGURATION_ROUTE.path);
CircadianCurves.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
MapNodes.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
Administration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
BuildingAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
GatewaysAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
// AdministrationFloorsConfig.configure(Routes.FLOORS_ADMIN_ROUTE.path);
FloorAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
UsersAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
// AdministrationUsersConfig.configure(Routes.USERS_ADMIN_ROUTE.path);
RolesAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
// AdministrationRolesConfig.configure(Routes.ROLES_ADMIN_ROUTE.path);
TokensAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
// AdministrationTokensConfig.configure(Routes.TOKENS_ADMIN_ROUTE.path);
MobileTokensAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
// AdministrationMobileTokensConfig.configure(Routes.MOBILE_TOKENS_ADMIN_ROUTE.path);

EmergencyLightingAdmin.config(); // to show the emergency lighting tab in admin view from within the ngjs pages
TenantGroupsAdministration.config(); // ANGULAR 16 UPGRADE: Configure
// DDLConfig.configure(Routes.DDL_ROUTE.path);
DdlAdministration.config(); // ANGULAR 16 UPGRADE: Configure migrated routes to keep old navigation logic intact
