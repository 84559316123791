import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import * as ng from 'angular';
import { ANGULAR_JS_MODULE_NAME } from '@common/app-constants';

export const Analytics = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'HeatmapNavigationInfo',
      'BuildingUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const Administration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'AdministrationNavigationInfo',
      'AdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const BuildingAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'BuildingAdministrationNavigationInfo',
      'BuildingAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const CircadianCurves = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'CircadianCurvesNavigationInfo',
      'CircadianCurvesUrlGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const MapNodes = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'MapNodesNavigationInfo',
      'MapNodesUrlGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const SensorNodes = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'SensorNodesNavigationInfo',
      'SensorNodesUrlGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const Dashboard = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'DashboardNavigationInfo',
      'DashboardUrlGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const EmergencyLighting = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'EmergencyLightingNavigationInfo',
      'EmergencyLightingUrlSchedulesGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const EmergencyLightingSchedule = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'EmergencyLightingScheduleNavigationInfo',
      'EmergencyLightingUrlSchedulesGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const EmergencyLightingLogs = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'EmergencyLightingLogsNavigationInfo',
      'EmergencyLightingUrlLogsGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const EmergencyLightingReports = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'EmergencyLightingReportsNavigationInfo',
      'EmergencyLightingUrlReportsGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const EmergencyLightingManualTests = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'EmergencyLightingManualTestNavigationInfo',
      'EmergencyLightingUrlManualTestsGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const EmergencyLightingAdmin = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'EmergencyLightingAdminNavigationInfo',
      'EmergencyLightingAdminUrlGenerator',
      (
        navigationService: NavigationService,
        navigationSectionInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationSectionInfo, urlGenerator));
      }
    ])
};

export const Buildings = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'BuildingsNavigationInfo',
      'BuildingsUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const NewBuilding = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'NewBuildingNavigationInfo',
      'NewBuildingUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};
export const GatewaysAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'GatewaysAdministrationNavigationInfo',
      'GatewaysAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const FloorAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'FloorsAdministrationNavigationInfo',
      'FloorAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const UsersAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'UsersAdministrationNavigationInfo',
      'UsersAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const MobileTokensAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'MobileTokensAdministrationNavigationInfo',
      'MobileTokenAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const RolesAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'RolesAdministrationNavigationInfo',
      'RoleAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const TokensAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'TokensAdministrationNavigationInfo',
      'TokenAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const TenantGroupsAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'TenantGroupsAdministrationNavigationInfo',
      'TenantGroupsAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const DdlAdministration = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'DDLAdministrationNavigationInfo',
      'DDLAdministrationUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const Integrations = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'IntegrationsNavigationInfo',
      'IntegrationsUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};

export const Beacons = {
  config: () =>
    ng.module(ANGULAR_JS_MODULE_NAME).run([
      'NavigationService',
      'BeaconsNavigationInfo',
      'BeaconsUrlGenerator',
      (
        navigationService: NavigationService,
        navigationServiceInfo: NavigationSectionInfo,
        urlGenerator: (buildingId?: number, floorId?: number) => string
      ) => {
        navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
      }
    ])
};
