<div class='or-tags' id="tags-component">
  <app-create-tag-form *ngIf='isCreateEnabled && !isBeaconConfig' (createTag)='createTag($event)'
                       [buildingId]='buildingId'></app-create-tag-form>
  <div #tagList [ngClass]="{'or-tag-list-container': !isBeaconConfig}">
    <div
      class='or-tags-list-item'
      *ngFor='let tag of tags$ | async'
      [ngStyle]='{ "background-color": "#" + tag.color }'
      [ngClass]='{ "or-active": tag.isActive }'>
      <div class='or-inline-item'>
        <button [attr.data-cy]="'tag-toggle-checkbox-' + tag.id" class='or-button or-tags-list-toggle' (click)='toggle(tag)'>
          <span
            class='or-icon or-small or-inverse or-icon-checkbox or-icon-shadow'
            [ngClass]='getCheckboxClass(tag)'
            *ngIf="isOfTenantType(tag)"
            appIsAuthorizedForBuilding="MANAGE_TENANT" [appBuildingIdToAuthorize]="buildingId">
          </span>
          <span
            class='or-icon or-small or-inverse or-icon-checkbox or-icon-shadow'
            [ngClass]='getCheckboxClass(tag)'
            *ngIf="!isOfTenantType(tag)">
          </span>
          <span [attr.data-cy]="'tenant-tag-icon-' + tag.id"
                class="or-icon or-tiny or-icon-tenant or-inverse"
                title='Tag is part of a Tenant'
                *ngIf="isOfTenantType(tag)">
          </span>
          <span [attr.data-cy]="'tag-name-label-' + tag.id"
                class='or-button-label or-tag-name'
                [ngStyle]='getTagNameMaxWidth()'
                [title]='tag.name'>
            {{tag.name}}
          </span>
        </button>
        <div class='or-inline-item or-tags-list-actions' *ngIf='isEditable'>
          <div class='or-inline-item'>
            <button
              [attr.data-cy]="'tag-edit-form-show-button-' + tag.id"
              [disabled]='isOfTenantType(tag) && !(hasTenantPermission() | async)' class='or-button or-iconic or-tags-list-action'
              (click)='tag.showEditForm = !tag.showEditForm'>
              <span class='or-icon or-tiny or-inverse or-icon-edit or-icon-shadow'></span>
            </button>
          </div>
          <div class='or-inline-item'>
            <button [attr.data-cy]="'delete-tag-button-' + tag.id"
                    [disabled]='isOfTenantType(tag)' class='or-button or-iconic or-tags-list-action'
                    (click)='deleteTag(tag)'>
              <span class='or-icon or-tiny or-inverse or-icon-cross or-icon-shadow'></span>
            </button>
          </div>
        </div>
      </div>
      <app-edit-tag-form *ngIf='tag.showEditForm' [tag]='tag'
                         (tagChanged)='tagChanged(tag, $event)'
                         (tagReset)='reset($event)'
                         (tagUpdate)='updateTag($event)'
                         [buildingId]='buildingId'></app-edit-tag-form>
    </div>
  </div>
</div>
