<div class="or-form or-beacon-settings">
    <ul class="or-beacon-settings-list pl-2 pr-2 w-full pb-4">
      <li class="or-beacon-settings-item p-2">
        <fieldset class="text-xl">
          <legend>
            {{ title }}
          </legend>
        </fieldset>
        <ul class="mt-3">
          <ng-content select="[panel-content]"></ng-content>
        </ul>
      <div class="or-form-footer mb-4 mt-4">
          <ng-content select="[footer-content]"></ng-content>
      </div>
      </li>
    </ul>
</div>