<fieldset [disabled]="!isReady">
  <app-panel [title]="'OFF &#8660; ON'">
    <div panel-content>
      <li class="w-[30%] block">
        <mat-slide-toggle color='primary' data-cy='beacon-enabled' [(ngModel)]="status"></mat-slide-toggle>
      </li>
    </div>
    <div footer-content>
      <app-panel-footer [disable]="isBusy" (resetHandler)="resetStatus()" (updateHandler)="setStatus()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Power Level'">
    <div panel-content>
      <li>
        <input class="or-input or-power-dbm" type="text" readonly id="dbm" data-cy="beacon-power-read" [(ngModel)]="powerLevel" />
        <input class="or-input or-no-shadow" type="range" min="0" max="6" data-cy="set-beacon-power"
        [(ngModel)]="indexedPower" (change)="convert()" value="0" />
      </li>
    </div>
    <div footer-content>
      <app-panel-footer [disable]="isBusy" (resetHandler)="resetPower()" (updateHandler)="setPowerLevel()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Beacon Content'">
    <div panel-content>
      <li>
        <mat-select class="or-select" data-cy="set-beacon-content" [(ngModel)]="content">
          @for (item of contents; track $index) {
            <mat-option  [value]="item"> {{item.name}}</mat-option>
          }
        </mat-select>
      </li>
    </div>
    <div footer-content>
      <app-panel-footer [disable]="isBusy" (resetHandler)="resetContent()" (updateHandler)="setContent()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Beaconing Repetition'">
    <div panel-content>
      <li>
        <input class="or-input or-no-shadow" type="range" min="0" max="2" data-cy="set-beacon-interval" [(ngModel)]="indexedInterval" list="steplist" />
        <datalist id="steplist">
          @for(item of intervals; track $index) {
            <option value="{{$index}}" label="{{item.name}}"></option>
          }
        </datalist>
      </li>
    </div>
    <div footer-content>
      <app-panel-footer [disable]="isBusy" (resetHandler)="resetInterval()" (updateHandler)="setInterval()"></app-panel-footer>
    </div>
  </app-panel>
  @if (showPanelBasedOnFeature) {
    <app-panel [title]="'UUID'">
      <div panel-content>
        <li>
          <mat-select class="or-select" data-cy="set-beacon-uuid" [(ngModel)]="uuid">
            @for (item of uuids; track $index) {
              <mat-option  [value]="item"> {{item.name}}</mat-option>
            }
          </mat-select>
        </li>
      </div>
      <div footer-content>
        <app-panel-footer [disable]="isBusy" (resetHandler)="resetUuid()" (updateHandler)="setUuid()"></app-panel-footer>
    </div>
    </app-panel>
    <app-panel [title]="'Major'">
      <div panel-content>
        <li  appIsAuthorized="MANAGE_BUILDINGS" isFeatureAvailable="beaconNimway">
            <input matInput class="or-input" type='number' data-cy="set-beacon-major" [(ngModel)]="major">
        </li>
      </div>
      <div footer-content>
        <app-panel-footer [disable]="isBusy" (resetHandler)="resetMajor()" (updateHandler)="setMajor()"></app-panel-footer>
    </div>

    </app-panel>
    <app-panel [title]="'Minor'">
      <div panel-content>
        <li  appIsAuthorized="MANAGE_BUILDINGS" isFeatureAvailable="beaconNimway">
          <input matInput class="or-input" type='number' data-cy="set-beacon-minor" [(ngModel)]="minor" disabled>
        </li>
      </div>
      <div footer-content>
        <app-panel-footer [disable]="true" (resetHandler)="resetMinor()" (updateHandler)="setMinor()" [submitLabel]="'Generate'"></app-panel-footer>
    </div>
    </app-panel>
  }
  </fieldset>
