import { Component, Input, OnInit } from '@angular/core';
import { MetricWidgetComponent } from '@app/analytics/metric-widget/metric-widget.component';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { Metric, MetricsTypeProvider, MetricType } from '@app/analytics/metric-widget/data-objects/metric-types';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';
import { Building } from '@app/shared/models/building.interface';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';

@Component({
  selector: 'app-metric-selector',
  templateUrl: './metric-selector.component.html',
  standalone: true,
  imports: [MetricWidgetComponent, AuthorizationModule],
  styleUrls: ['./metric-selector.component.scss']
})
export class MetricSelectorComponent implements OnInit {
  @Input({ required: true }) building: Building;
  @Input({ required: true }) floorId: number;
  dataTypeStatuses: Map<DataType, boolean> = new Map();
  types: { [key in MetricType]: Metric };

  constructor(private metricService: AnalyticsMetricsService) {}

  ngOnInit(): void {
    const dataTypes = DataType.all();
    dataTypes.forEach((dataType) => (this.dataTypeStatuses[dataType.toString()] = false));
    this.setActive(this.metricService.getCurrentContextDataType());
    this.types = MetricsTypeProvider.metrics;
  }

  get MetricType(): typeof MetricType {
    return MetricType;
  }

  setActive(dataType: DataType): void {
    for (const dataTypeStatus in this.dataTypeStatuses) {
      this.dataTypeStatuses[dataTypeStatus] = dataType.toString() === dataTypeStatus;
    }
  }

  isActive(dataType: string): boolean {
    return this.dataTypeStatuses[dataType] || false;
  }
}
