@if (building) {
  <mat-drawer-container autosize [hasBackdrop]="true">
    <mat-drawer #tagsDrawer mode="over" position="end">
      <div class="w-[250px] p-[20px]">
        <app-tags
          [isBeaconConfig]="true"
          [buildingId]="building?.id"
          [tags$]="tags$"
          [isEditable]="false"
          [isCreateEnabled]="false"
          [isNodeTaggingModeActive]="false"
          (tagSelectionChange)="toggleTag($event)">
        </app-tags>
      </div>
    </mat-drawer>
    <div class="main-content">
      <div class="grid grid-cols-12 gap-[10px] pt-[10px] px-[20px]">
        <app-panel-toggle class="col-span-3" icon="node" tooltip="Nodes" (togglePanel)="toggleLeft($event)" [toggleState]="isLeftColHidden"></app-panel-toggle>
        <div [ngClass]="isRightColHidden ? 'col-span-8' : 'col-span-7'"></div>
        <div class="flex" [ngClass]="isRightColHidden ? 'hidden' : ''">
          <app-panel-toggle icon="gear" tooltip="Configuration" (togglePanel)="toggleRight($event)" [toggleState]="isRightColHidden"></app-panel-toggle>
        </div>
        <div class="flex justify-end">
          <app-panel-toggle [ngClass]="isRightColHidden ? '' : 'hidden'" tooltip="Configuration" icon="gear" (togglePanel)="toggleRight($event)" [toggleState]="isRightColHidden"></app-panel-toggle>
          <app-panel-toggle class="pl-0.5" icon="tag" tooltip="Tags" (click)="tagsDrawer.toggle()" [toggleState]="tagsDrawer.opened"></app-panel-toggle>
        </div>
      </div>
      <div id="beacons" class="grid grid-rows-12 grid-cols-12 gap-[10px] py-[10px] px-[20px]">
        <div class="row-span-12 h-full bg-white shadow-dark" [ngClass]="nodeListPanelClass">
          <app-node-list-card
            [tableColumns]='nodeListTableColumns'
            [sortByFields]='sortByFields'
            [buildingId]="building?.id"
            [floorId]="floorId"
            [isDriverDetailsActive]='false'/>
        </div>
        <div class="row-span-12 bg-white rounded-sm text-inverse shadow-dark h-full relative" [ngClass]="floorPanelClass">
          <app-floorplan [buildingId]="building.id" (onNodeClick)="nodeClick($event)" />
          <button mat-fab
                  color="primary"
                  [disabled]="!isSelectedNodeReady"
                  [matTooltip]="isSelectedNodePending() ? 'Waiting for Response': 'Query Selected Node'"
                  matTooltipClass="mat-tooltip font-15"
                  matTooltipPosition="above"
                  aria-label="Button to refresh nodes in the floorplan"
                  (click)="querySelectedNodes()"
                  class="query-node-button"
                  data-cy="btn-refresh-nodes-floorplan">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="row-span-12 overflow-y-auto overflow-x-hidden p-[8px] shadow-dark" [ngClass]="configPanelClass">
          <app-configuration [buildingId]="building?.id" [floorId]='floorId' [isReady]="isSelectedNodeReady" />
        </div>
      </div>
    </div>
  </mat-drawer-container>
}
