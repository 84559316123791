<div class='or-floorplan-scale-gradient' [ngStyle]='gradientStyle'>
  <div class='or-floorplan-scale'>
    <div class='or-floorplan-scale-percentage'>
      Low
    </div>
    <div class='or-floorplan-scale-percentage white'>
      High
    </div>
  </div>
</div>
