<div class="or-metric-selector">
  <ul class="or-metric-selector-list">
    <li class="or-metric-selector-list-item">
      <app-metric-widget
        [building]="building"
        [floorId]="floorId"
        [metric]="types[MetricType.PRESENCE]"
        [isActive]='isActive("PRESENCE")'
        (setActive)='setActive($event)'
      ></app-metric-widget>
    </li>
    <li class="or-metric-selector-list-item">
      <app-metric-widget
        [building]="building"
        [floorId]="floorId"
        [metric]="types[MetricType.DIM_LEVEL]"
        [isActive]='isActive("DIM_LEVEL")'
        (setActive)='setActive($event)'
      ></app-metric-widget>
    </li>
    <li class="or-metric-selector-list-item"
        [appIsAuthorized]="'VIEW_HIDDEN_COMPONENTS'">
      <app-metric-widget
        [building]="building"
        [floorId]="floorId"
        [metric]="types[MetricType.LIGHT_LEVEL]"
        [isActive]='isActive("LIGHT_LEVEL")'
        (setActive)='setActive($event)'
      ></app-metric-widget>
    </li>
    <li class="or-metric-selector-list-item">
      <app-metric-widget
        [building]="building"
        [floorId]="floorId"
        [metric]="types[MetricType.ENERGY_CONSUMPTION]"
        [isActive]='isActive("ENERGY_CONSUMPTION")'
        (setActive)='setActive($event)'
      ></app-metric-widget>
    </li>
    <li class="or-metric-selector-list-item"
        [appIsAuthorized]="'VIEW_HIDDEN_COMPONENTS'">
      <app-metric-widget
        [building]="building"
        [floorId]="floorId"
        [metric]="types[MetricType.OCCUPANCY]"
        [isActive]='isActive("OCCUPANCY")'
        (setActive)='setActive($event)'
      ></app-metric-widget>
    </li>
    <li class="or-metric-selector-list-item"
        [appIsAuthorized]="'VIEW_HIDDEN_COMPONENTS'">
      <app-metric-widget
        [building]="building"
        [floorId]="floorId"
        [metric]="types[MetricType.CONNECTION_QUALITY]"
        [isActive]='isActive("CONNECTION_QUALITY")'
        (setActive)='setActive($event)'
      ></app-metric-widget>
    </li>
  </ul>
</div>
