<div id='orIntegrationsAreas'>
  <div class='or-content'>
    <div class='or-content-header'>
      <ul class='or-inline-items or-pull-left'>
        <li class='or-inline-item'>
        </li>
      </ul>
    </div>
    @if(isGatewaysLoaded) {
      <div class='or-content-body'>
        @if(areas?.length === 0) {
          <div class="or-banner or-banner-no-areas">
            <div class="or-banner-header"><h1>There are no areas</h1></div>
            <div class="or-banner-content"><p>Go ahead and set up one.</p></div>
          </div>
        }
        <ul class='or-stacked-items or-integrations-areas-list'>
            <li *ngFor="let area of areas; let i = index" class='or-stacked-item or-integrations-areas-list-item' >
              <app-bacnet-area-tile [area]='area' [isNew]='false' [gateways]="gateways" [enableLabels]="i === 0"
                                      (valueChanged)='loadAreas()' data-cy="existing-bacnet-areas-form">
              </app-bacnet-area-tile>
            </li>

        <div class="mt-8">
          <div>New Area</div>
          <app-bacnet-area-tile [area]='newArea' [isNew]='true' [gateways]="gateways" [enableLabels]="true"
                                (valueChanged)='loadAreas()' data-cy="new-bacnet-areas-form">
          </app-bacnet-area-tile>
        </div>
        </ul>
      </div>
    }
  </div>
</div>
