<div class="or-metric-widget-chart">
  <div class="or-metric-widget-chart-content">
    <div
        [class]="metric.className"
        [ngClass]="{ 'or-metric-widget': true, 'or-active': isActive, 'or-busy': isBusy }"
        oncontextmenu="return false;"
    >
      <ul class="flex flex-row or-metric-widget-heading">
        <li class=" grow or-inline-item">
          <button
              class="or-button or-metric-widget-toggle"
              (click)="setActiveAndRefresh()"
              [ngClass]="{ 'or-active': isActive }"
              [attr.data-cy]="metric.name + '-metric-widget-btn'"
          >
            <span class="or-icon or-small or-inverse or-metric-widget-icon" [ngClass]="metric.iconClass"></span
            ><span class="or-button-label or-metric-widget-label">{{ metric.label }}</span>
          </button>
        </li>
        <li class="flex-non or-inline-item w-12">
          @if (isActive) {
          <button class="or-button or-pull-right" (click)="copyToClipboard()" [attr.data-cy]="metric.name + 'copy-to-clipboard-btn'">
            <span class="or-small-to-tiny or-icon or-small or-inverse or-icon-copy"></span>
          </button>
          }
        </li>
      </ul>
      <div class="or-metric-widget-content">
        <div class="or-metric-widget-navigation">
          <ul class="or-inline-items or-metric-widget-navigation-shift">
            <li class="or-inline-item">
              <button class="or-button or-iconic" (click)="shiftTimeframeBack()" [disabled]="!canShiftTimeframe" [attr.data-cy]="metric.name + '-shift-time-back-btn'">
                <span class="or-icon or-small or-inverse or-icon-arrow-left"></span>
              </button>
            </li>
            <li class="or-inline-item">
              <button class="or-button or-iconic" (click)="shiftTimeframeForward()" [disabled]="!canShiftTimeframe" [attr.data-cy]="metric.name + '-shift-time-forward-btn'">
                <span class="or-icon or-small or-inverse or-icon-arrow-right"></span>
              </button>
            </li>
          </ul>
          <button class="or-button or-metric-widget-navigation-reset" >
            <!-- (click)="resetTimeframe()"> -->
            <span class="or-button-label" [attr.data-cy]="metric.name + '-timeframe-label'">{{ timeframeLabel }}</span>
          </button>
          <ul class="or-inline-items or-metric-widget-navigation-zoom">
            <li class="or-inline-item">
              <button class="or-button or-iconic" (click)="zoomIn()" [disabled]="!canZoomIn" [attr.data-cy]="metric.name + '-zoom-in-btn'">
                <span class="or-icon or-small or-inverse or-icon-plus"></span>
              </button>
            </li>
            <li class="or-inline-item">
              <button class="or-button or-iconic" (click)="zoomOut()" [disabled]="!canZoomOut" [attr.data-cy]="metric.name + '-zoom-out-btn'">
                <span class="or-icon or-small or-inverse or-icon-minus"></span>
              </button>
            </li>
          </ul>
        </div>
        <div class="or-metric-widget-chart">
          <svg class="or-metric-widget-chart-content" #OrMetricWidgetChartContent [attr.data-cy]="metric.name + '-data-chart'"></svg>
        </div>
      </div>
    </div>
  </div>
</div>
