<div>
  @if (bacnetForm){
  <form [formGroup]="bacnetForm" class="or-form or-form-bacnet-area-tile" [ngClass]="{'or-form-bacnet-area-tile-enable-labels':enableLabels }"
        novalidate="novalidate">
    <ul class="or-form-items">
      <li class="or-form-item or-form-bacnet-area-tile-id">
        <span class="or-label or-label-above">Area Id</span>
        <input class="or-input" maxlength="256" formControlName="areaId" placeholder="AUTO" type="text" />
      </li>
      <li class="or-form-item or-form-bacnet-area-tile-name">
        <span class="or-label or-label-above">Name (BACnet Group)</span>
        <input class="or-input" maxlength="256" [disabled]="isBusy()" formControlName="areaName" type="text" data-cy="bacnet-area-name" />
      </li>
      <li class="or-form-item or-form-bacnet-area-tile-description">
        <span class="or-label or-label-above">Description</span>
        <input class="or-input" maxlength="256" [disabled]="isBusy()" formControlName="areaDescription" type="text" data-cy="bacnet-area-description"/>
      </li>
      <li class="or-form-item or-form-bacnet-area-tile-gateway-info">
      <span class="or-label or-label-above">Gateway Information ( Address [ and Name if available ] )</span>
        <select class="or-select" maxlength="256" [disabled]="isBusy()" formControlName="gatewayId"
                type="text" data-cy="bacnet-area-gateway">
          @for (gatewayOption of gatewayOptions; track gatewayOption.id ){
            <option value="{{gatewayOption.id}}">{{gatewayOption.label}}</option>
          }
        </select>
      </li>
      <li class="or-form-item or-form-bacnet-area-tile-portal-id">
        <span class="or-label-portal-id or-label-above" appIsAuthorized="VIEW_HIDDEN_COMPONENTS">Portal Id</span>
        <input class="or-input" appIsAuthorized="VIEW_HIDDEN_COMPONENTS" maxlength="256"
          formControlName="id" placeholder="AUTO" type="text" />
      </li>
      <li class="or-form-item or-form-bacnet-area-tile-actions">
        <ul class="or-inline-items">
          @if(isNew) {
          <li class="or-inline-item" >
            <button class="or-button or-iconic or-primary or-positive" type="button" (click)="submit()"
              [disabled]="isBusy() || bacnetForm.pristine" title="Create Area" data-cy="create-bacnet-area">
              <span class="or-icon or-small or-inverse or-icon-plus"></span>
            </button>
          </li>
          } @else {
            <li class="or-inline-item">
              <button
                class="or-button or-iconic or-primary or-positive" type="button" (click)="submit()"
                [disabled]="isBusy() || bacnetForm.pristine" title="Update Area" data-cy="update-bacnet-area">
                <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
              </button>
            </li>
            <li class="or-inline-item">
              <button class="or-button or-iconic or-primary or-negative" type="button" (click)="deleteArea()"
                      [disabled]="isBusy()" title="Delete Area" data-cy="delete-bacnet-area">
                <span class="or-icon or-small or-inverse or-icon-trash"></span>
              </button>
            </li>
          }
            <li class="or-inline-item">
              <button class="or-button or-iconic" type="button" (click)="discardChanges()" [disabled]="isBusy() || bacnetForm.pristine"
                      title="Reset Changes" data-cy="reset">
                <span class="or-icon or-small or-icon-reload"></span>
              </button>
            </li>
        </ul>
      </li>
    </ul>
  </form>
  }
</div>
