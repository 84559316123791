@if (showHidden && tableColumns) {
  <div class='or-nodes-list'>
    <div class='or-nodes-list-control'>
      <app-nodes-filter
        [filterForm]="filterForm"
        [sortByFields]="sortByFields"
        (filterChange)="onFilterChanged($event)"
        (copyNodesToClipboard)="copyToClipboard()"
        (refreshNodeListEmitter)="refreshNodeList()"></app-nodes-filter>
    </div>
    @if(dataSource.isLoading$ | async) {
      <div class="mat-spinner spinner-container">
        <mat-spinner diameter="80" strokeWidth="8"></mat-spinner>
      </div>
    }
    <div class='nodes-table-parent'>
      @for(node of dataSource.connect(null)| async; track node) {
        <mat-card class='mt-1 mb-1 p-5' (click)='toggleRow(node)' [ngClass]="{'row-selected': node.selected}">
          <mat-card-content>
            <div class='flex flex-row flex-wrap justify-between'>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                  <div [ngClass]="getNodeStatusAndTypeClass(node)">
                    <span class="or-icon or-small or-icon-node"></span>
                  </div>
                  <div class='or-node-tile-label'>Node</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-id-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    {{node.id}}
                  </div>
                <div class='or-node-tile-label'>Node Id</div>
                <br>
              </div>
              <div [attr.data-cy]="'node--' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  {{ node.groupId || 'NA' }}
                </div>
                <div class='or-node-tile-label'>Group Id</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  {{ (node.address || '').toString(16).toLocaleUpperCase() }}
                </div>
                <div class='or-node-tile-label'>Address</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  {{ getPropValForLuminaire(node, 'burnInHours') }}
                </div>
                <div class='or-node-tile-label'>Burn Hours</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  {{ getPropValForLuminaire(node, 'energyConsumption') === 'N/A' ? 'N/A' : getPropValForLuminaire(node, 'energyConsumption') + 'kWh' }}
                </div>
                <div class='or-node-tile-label'>Energy Consumption</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  <img [ngClass]='bleScanningClass(node)'/>
                </div>
                <div class='or-node-tile-label'>Ble Scanning</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  <ul>
                    <li>
                      <span class="or-icon or-tiny or-icon-node"></span>
                      <span class="or-node-tile-value or-node-tile-inline-text">{{node.luminaireDrivers?.length}}</span>
                    </li>
                    <li>
                      <span class="or-icon or-tiny or-icon-emergency"></span>
                      <span class="or-node-tile-value or-node-tile-inline-text">{{node.emDrivers?.length}}</span>
                    </li>
                  </ul>
                </div>
                <div class='or-node-tile-label'>Devices</div>
                <br>
              </div>
            </div>
          </mat-card-content>
          <mat-card-footer>
            @if (!node.expanded) {
              <div [isFeatureAvailable]="'tagsEverywhere'">
                @for(tag of node.tags; track tag.name) {
                  <div class="or-node-tile-tag">
                    <span class="or-node-tile-tag-thumbnail" title="{{ tag.name }}" [ngStyle]='{ "background-color": "#" + tag.color }'></span>
                  </div>
                }
              </div>
            } @else {
              <div>
                @for (tag of node.tags; track tag.id) {
                  <div class="or-node-tile-details-tags-item mt-1 mb-1">
                    <span class="or-node-tile-details-tag" [ngStyle]="getForegroundColor(tag.color)">
                      <span class="or-node-tile-details-tag-name">{{tag.name}}</span>
                    </span>
                  </div>
                }
                <hr>
                @if (node?.alerts?.length !== 0 && isDriverDetailsActive) {
                  @for (alert of node.alerts; track $index) {
                    <div class="node-alerts py-2 px-6 m-1">{{alert.value}}</div>
                  }
                }
              </div>
              <br>
              <div class=''>
                @for(lumDriver of node.luminaireDrivers; track lumDriver) {
                  <div class='flex flex-row flex-wrap justify-between w-[70%] pt-2 pb-2'>
                    @for (innerColumn of subTableColumns; track innerColumn) {
                      <div [attr.data-cy]="getDataCy(innerColumn, lumDriver[innerColumn])">
                        @if (innerColumn === 'type') {
                          <span class="{{'or-luminaire-driver-tile-status-body or-luminaire-driver-tile-status-body-' + lumDriver.status}}">
                            <span class="or-icon or-tiny or-icon-node"></span>
                          </span>
                        } @else {
                          {{getProperValue(innerColumn, lumDriver[innerColumn])}}
                        }
                        <br>
                        <span class="or-node-tile-label">{{innerColumn}}</span>
                      </div>
                    }
                  </div>
                }
                @for (emDriver of node.emDrivers; track emDriver) {
                  <div class='flex flex-row flex-wrap justify-between w-[70%] pt-2 pb-2'>
                    @for (innerColumn of subTableColumns; track innerColumn) {
                      <div [attr.data-cy]="getDataCy(innerColumn, emDriver[innerColumn])">
                        @if (innerColumn === 'type') {
                          <span class="{{'or-em-driver-tile-status-body or-em-driver-tile-status-body-' + emDriver.status}}">
                            <span class="or-icon or-tiny or-icon-emergency"></span>
                          </span>
                        } @else {
                          {{getProperValue(innerColumn, emDriver[innerColumn])}}
                        }
                        <br>
                        <span class="or-node-tile-label">{{innerColumn}}</span>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </mat-card-footer>
        </mat-card>
      }
    </div>
  </div>
}

