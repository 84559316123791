import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FloorplanActionsTrayComponent,
  TrayButton
} from '@app/shared/components/floorplan/floorplan-actions-tray/floorplan-actions-tray.component';
import { SensorNodeService } from '@services/sensor-node.service';
import { FloorplanService } from '@services/floorplan.service';
import { ToastService } from '@services/toast/toast.service';
import { CommonModule } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { DISCRIMINATOR } from '@app/shared/models/selectable.interface';
import { SensorNodeIdsBatch } from '@app/shared/models/sensor-node-batch.interface';
import { EnhancedSelectable } from '@angularjs/angular/modules/layout/or-floorplan-actions/OrFloorplanActionsController';
import { MappingService } from '@services/mapping.service';
import { PassiveNodeService } from '@services/passive-node.service';
import { SensorNodeIdAndAddressDTO } from '@app/shared/models/sensor-node-id-and-address-dto.interface';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData, DialogType, MatDialogData } from '@components/dialogs/confirm/confirm.component';
import { MatSelectModule } from '@angular/material/select';
import { VirtualNotificationDTO } from '@app/shared/models/virtual-notification-dto.interface';
import { SelectableNode } from '@app/shared/models/sensor-node';
import { FormsModule } from '@angular/forms';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';

@Component({
  selector: 'app-floorplan-actions',
  standalone: true,
  imports: [
    AuthorizationModule,
    CommonModule,
    FloorplanActionsTrayComponent,
    MatTooltipModule,
    MatSelectModule,
    FormsModule
  ],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true // this is to disable the default behaviour of interacting with the tooltip
      }
    }
  ],
  templateUrl: './floorplan-actions.component.html',
  styleUrls: ['./floorplan-actions.component.scss']
})
export class FloorplanActionsComponent implements OnInit {
  addTray: TrayButton[] = [];
  availableUnmappedPNList: SensorNodeIdAndAddressDTO[] = [];
  hasAddTray: boolean;
  hasHeatMap: boolean;
  hasMappingTray: boolean;
  hasMoveTray: boolean;
  hasNodeOptionsTray: boolean;
  hasSelectionTray: boolean;
  isEmergencyLightingPage: boolean;
  mapNodesTray: TrayButton[] = [];
  moveTray: TrayButton[] = [];
  nodeOptionsTray: TrayButton[] = [];
  selectionTray: TrayButton[] = [];
  showTray: TrayButton[] = [];
  isGatewayModeAllowed: boolean;
  selectedPnId: number | null = null;
  private readonly INVALID_NODE_TYPE_UNMAP_MSG = 'Please select only 1 type of node to map or unmap';
  private readonly PUBLISHER_UNMAP_MSG =
    'Selected node(s) is a publisher and subscribed passive nodes will be affected. Do you want to unmap?';
  private readonly UNGROUPED_PNS_IN_SELECTION = 'No Passive Nodes without a Group in Selection';
  private readonly UNMAPPING_FAILED = 'Could not unmap selected nodes';
  private readonly UNMAP_NODE_MSG = 'Do you want to unmap selected node(s)?';
  private readonly DRIVER_RESET_SUCCESSFUL = 'Driver reset was successful';
  private readonly ONLY_MAPPED_CAN_BE_UNMAPPED = 'Only mapped nodes can be un-mapped';
  private readonly BLE_QUERY_SUCCESS = 'BLE scanning query sent to all compatible selected devices';
  private readonly BLE_ON_SUCCESS = 'BLE scanning enabled on all compatible selected devices';
  private readonly BLE_OFF_SUCCESS = 'BLE scanning disabled on all compatible selected devices';
  private readonly SELECT_TO_MAP_UNMAP = 'Please select at least one node to map or unmap from the floor';
  private readonly ERROR_MAPPING_VIRT = 'There was an error mapping virtual node to actual passive node';
  private readonly SN3_UNMAPPED_SUCCESS = 'Node unmapped successfully';

  @Input({ required: true }) buildingId: number;
  @Input({ required: true }) floorId: number;
  @Output() addNodeClicked = new EventEmitter<boolean>();
  @Output() cancelTestsInBatch = new EventEmitter<string>();
  @Output() discardAddClicked = new EventEmitter<void>();
  @Output() discardMoveClicked = new EventEmitter<void>();
  @Output() runTestsInBatch = new EventEmitter<string>();
  @Output() saveAddedNodesClicked = new EventEmitter<void>();
  @Output() saveMoveClicked = new EventEmitter<void>();
  @Output() undoAddClicked = new EventEmitter<void>();
  @Output() undoMoveClicked = new EventEmitter<void>();
  @Output() pnMappingModeClicked = new EventEmitter<boolean>();
  @Output() snMappingModeClicked = new EventEmitter<boolean>();

  constructor(
    private floorplanService: FloorplanService,
    private mappingService: MappingService,
    private passiveNodeService: PassiveNodeService,
    private sensorNodeService: SensorNodeService,
    private toastService: ToastService,
    private dialogService: ConfirmationDialogService,
    private metricService: AnalyticsMetricsService
  ) {}

  ngOnInit(): void {
    this.buildingId = Number(this.buildingId);
    this.floorId = Number(this.floorId);
    this.isEmergencyLightingPage = this.floorplanService.isElmtPage;
    this.hasHeatMap = this.floorplanService.isAnalyticsPage;
    this.initialiseShowTray();
    if (this.floorplanService.isSensorNodesPage) {
      this.initializeAddTray();
      this.initializeMoveTray();
      this.initializeNodeOptionsTray();
      this.initializeMapNodesTray();
      this.initialiseUnmappedPNList();
      this.isGatewayModeAllowed = true;
    }
    this.initializeSelectionTray();
  }

  private initialiseShowTray(): void {
    if (!this.floorplanService.isLightingConfigPage) {
      this.showTray.push(
        new TrayButton(
          'Enable All Nodes',
          'or-icon-node',
          () => {
            this.toggleNodes();
          },
          () => {
            return this.floorplanService.isEnableAllNodesSelected;
          },
          false,
          'enable-all-nodes-button'
        )
      );
    }
    if (this.floorplanService.isSensorNodesPage || this.floorplanService.isElmtPage) {
      this.showTray.push(
        new TrayButton(
          'Show attached Devices',
          'or-icon-driver',
          () => {
            this.toggleDrivers();
          },
          () => {
            return this.floorplanService.isShowDriversModeActive;
          },
          false,
          'show-drivers-button'
        )
      );
    }
    if (this.floorplanService.isSensorNodesPage || this.floorplanService.isAnalyticsPage) {
      this.showTray.push(
        new TrayButton(
          'Show Subscriber connections',
          'or-icon-pub-sub',
          () => {
            this.toggleSubscriberConnections();
          },
          () => {
            return this.floorplanService.arePubSubConnectionsEnabled;
          },
          false,
          'subscriber-connections-hide'
        )
      );
    }
    if (this.floorplanService.isAnalyticsPage) {
      this.showTray.push(
        new TrayButton(
          'Enable Heatmap',
          'or-icon-heatmap',
          () => {
            this.toggleHeatmap();
          },
          () => {
            return this.floorplanService.isHeatmapModeActive;
          },
          false,
          'enable-heatmap-button'
        )
      );
      this.showTray.push(
        new TrayButton(
          'Highlight Emergency Lights',
          'or-icon-emergency',
          () => {
            this.toggleHighlightEmergencyLights();
          },
          () => {
            return this.floorplanService.isHighlightEmergencyLightsModeActive;
          },
          false,
          'highlight-emergency-lights-button'
        )
      );
    }
    if (this.floorplanService.isSensorNodesPage) {
      this.showTray.push(
        new TrayButton(
          'Show Faulty',
          'or-icon-node-faulty',
          () => {
            this.toggleFaultyNodes();
          },
          () => {
            return this.floorplanService.isShowFaultyNodesModeActive;
          },
          false,
          'show-faulty-button'
        )
      );
      this.showTray.push(
        new TrayButton(
          'Show Mapped',
          'or-icon-node-mapped',
          () => {
            this.toggleMappedNodes();
          },
          () => {
            return this.floorplanService.showMappedNodesMode;
          },
          false,
          'show-mapped-button'
        )
      );
      this.showTray.push(
        new TrayButton(
          'Show Unmapped',
          'or-icon-node-unmapped',
          () => {
            this.toggleUnmappedNodes();
          },
          () => {
            return this.floorplanService.isUnmappedModeActive;
          },
          false,
          'show-unmapped-button'
        )
      );
    }
  }

  private initializeAddTray(): void {
    this.hasAddTray = true;
    this.addTray.push(
      new TrayButton(
        'Save',
        'or-icon-checkmark',
        () => {
          this.save();
        },
        null,
        true,
        'add-tray-save-button'
      )
    );
    this.addTray.push(
      new TrayButton(
        'Undo',
        'or-icon-undo',
        () => {
          this.undoAdd();
        },
        null,
        false,
        'add-tray-undo-button'
      )
    );
    this.addTray.push(
      new TrayButton(
        'Discard',
        'or-icon-cross',
        () => {
          this.discard();
        },
        null,
        true,
        'add-tray-discard-button'
      )
    );
    this.hasAddTray = this.addTray.length !== 0;
  }

  private initializeMoveTray(): void {
    this.hasMoveTray = true;
    this.moveTray.push(
      new TrayButton(
        'Move All Relatively',
        'or-icon-selection',
        () => {
          this.toggleMoveAllMode();
        },
        () => {
          return this.floorplanService.isMoveAllModeActive;
        },
        false,
        'move-all-relatively-button'
      )
    );
    this.moveTray.push(
      new TrayButton(
        'Save',
        'or-icon-checkmark',
        () => {
          this.saveMove();
        },
        null,
        true,
        'move-save-button'
      )
    );
    this.moveTray.push(
      new TrayButton(
        'Undo',
        'or-icon-undo',
        () => {
          this.undoMove();
        },
        null,
        false,
        'move-undo-button'
      )
    );
    this.moveTray.push(
      new TrayButton(
        'Discard',
        'or-icon-cross',
        () => {
          this.discardMove();
        },
        null,
        true,
        'move-discard-button'
      )
    );
    this.hasMoveTray = this.moveTray.length !== 0;
  }

  private initializeSelectionTray(): void {
    this.hasSelectionTray = true;
    if (this.floorplanService.isLightingConfigPage) {
      this.selectionTray.push(
        new TrayButton(
          'Clear Selection',
          'or-icon-selection-clear',
          () => {
            this.clear();
          },
          null,
          false,
          'selection-clear-button'
        )
      );
    } else {
      this.selectionTray.push(
        new TrayButton(
          'Area Selection',
          'or-icon-selection',
          () => {
            this.toggleAreaSelection();
          },
          () => {
            return this.floorplanService.isAreaModeActive;
          },
          false,
          'selection-area-button'
        )
      );
      this.selectionTray.push(
        new TrayButton(
          'Cumulative Selection',
          'or-icon-selection-add',
          () => {
            this.toggleCumulativeSelection();
          },
          () => {
            return this.floorplanService.isCumulativeModeActive;
          },
          false,
          'selection-add-button'
        )
      );
      this.selectionTray.push(
        new TrayButton(
          'Select All',
          'or-icon-selection-all',
          () => {
            this.selectAll();
          },
          null,
          false,
          'selection-all-button'
        )
      );
      this.selectionTray.push(
        new TrayButton(
          'Clear Selection',
          'or-icon-selection-clear',
          () => {
            this.clear();
          },
          null,
          false,
          'selection-area-button'
        )
      );
    }

    if (this.floorplanService.isSensorNodesPage) {
      this.selectionTray.push(
        new TrayButton(
          'Delete',
          'or-icon-trash',
          () => {
            this.delete();
          },
          null,
          false,
          'selection-delete-button'
        )
      );
    }
    this.hasSelectionTray = this.selectionTray.length !== 0;
  }

  private confirmAndResetDrivers(): void {
    if (this.sensorNodeService.selectedEntities.length === 0) {
      this.toastService.error({ message: 'No nodes selected', dataCy: 'send-error-toast' });
      return;
    }
    const dialogData: MatDialogData = {
      message: 'Are you sure you want to reset the drivers for the selected nodes?',
      showConfirm: true,
      title: 'Reset Drivers',
      type: DialogType.INFO
    };
    this.dialogService.open(dialogData).subscribe({
      next: (hasConfirmed) => {
        if (hasConfirmed) {
          this.sensorNodeService.resetDriversForSelectedNodes().subscribe({
            next: (clearedNodes: number) => {
              if (clearedNodes > 0) {
                this.toastService.success({ message: this.DRIVER_RESET_SUCCESSFUL, dataCy: 'send-success-toast' });
                this.sensorNodeService.fetchNodes(this.floorId, this.buildingId);
              }
            },
            error: (error) => {
              this.toastService.error({ message: error, dataCy: 'send-error-toast' });
            }
          });
        }
      }
    });
  }

  private initializeNodeOptionsTray(): void {
    this.hasNodeOptionsTray = true;
    this.nodeOptionsTray.push(
      new TrayButton(
        'Clear attached Devices',
        'or-icon-node-reset',
        () => {
          this.confirmAndResetDrivers();
        },
        null,
        false,
        'reset-driver-button'
      )
    );
    this.nodeOptionsTray.push(
      new TrayButton(
        'Unmap Nodes',
        'or-icon-node-unmap',
        () => {
          this.unmap();
        },
        null,
        false,
        'selection-unmap-button',
        true
      )
    );
    this.nodeOptionsTray.push(
      new TrayButton(
        'Query BLE',
        'or-icon-ble-query',
        () => {
          this.sensorNodeService.queryBleScanning(this.buildingId).subscribe({
            next: () =>
              this.toastService.success({
                message: this.BLE_QUERY_SUCCESS,
                dataCy: 'send-success-toast'
              }),
            error: (error) => this.toastService.error({ message: error, dataCy: 'send-error-toast' })
          });
        },
        null,
        false,
        'selection-ble-query-button'
      )
    );
    this.nodeOptionsTray.push(
      new TrayButton(
        'Turn on BLE',
        'or-icon-ble-on',
        () => {
          this.sensorNodeService.enableBleScanning(this.buildingId).subscribe({
            next: () =>
              this.toastService.success({
                message: this.BLE_ON_SUCCESS,
                dataCy: 'send-success-toast'
              }),
            error: (error) => this.toastService.error({ message: error, dataCy: 'send-error-toast' })
          });
        },
        null,
        false,
        'selection-ble-on-button'
      )
    );
    this.nodeOptionsTray.push(
      new TrayButton(
        'Turn off BLE',
        'or-icon-ble-off',
        () => {
          this.sensorNodeService.disableBleScanning(this.buildingId).subscribe({
            next: () =>
              this.toastService.success({
                message: this.BLE_OFF_SUCCESS,
                dataCy: 'send-success-toast'
              }),
            error: (error) => this.toastService.error({ message: error, dataCy: 'send-error-toast' })
          });
        },
        null,
        false,
        'selection-ble-off-button'
      )
    );
  }

  private initializeMapNodesTray(): void {
    this.hasMappingTray = true;
    this.mapNodesTray.push(
      new TrayButton(
        'Map Sensor Nodes',
        'or-icon-s-node',
        () => {
          this.toggleSensorNodeMappingMode();
        },
        () => {
          return this.floorplanService.isSensorNodeMappingModeActive;
        },
        false,
        's-node-button'
      )
    );
    this.mapNodesTray.push(
      new TrayButton(
        'Map Passive Nodes',
        'or-icon-p-node',
        () => {
          this.togglePassiveNodeMappingMode();
        },
        () => {
          return this.isPassiveNodeMappingModeEnabled;
        },
        false,
        'p-node-button'
      )
    );
  }

  get isPassiveNodeMappingModeEnabled(): boolean {
    return this.floorplanService.isPnMappingModeActive;
  }

  get shouldDisableAddButton(): boolean {
    return (
      this.floorplanService.isSensorNodeMappingModeActive ||
      this.floorplanService.isPnMappingModeActive ||
      this.floorplanService.isMoveModeActive
    );
  }

  get shouldDisableMoveTrayBtns(): boolean {
    return !this.floorplanService.isMoveModeActive;
  }

  get shouldDisableAddTrayBtns(): boolean {
    return !this.floorplanService.isAddModeEnabled;
  }

  get shouldDisableMoveButton(): boolean {
    return (
      this.floorplanService.isSensorNodeMappingModeActive ||
      this.floorplanService.isPnMappingModeActive ||
      this.floorplanService.isAddModeEnabled
    );
  }

  get shouldDisableSelectionButton(): boolean {
    return (
      this.floorplanService.isSensorNodeMappingModeActive ||
      this.floorplanService.isPnMappingModeActive ||
      this.floorplanService.isMoveModeActive ||
      this.floorplanService.isAddModeEnabled
    );
  }

  get disableMapping(): boolean {
    return this.floorplanService.isMoveModeActive || this.floorplanService.isAddModeEnabled;
  }

  mapOrUnmapSelectedPassiveNode(): void {
    const nodes = this.sensorNodeService.selectedEntities.filter(
      (n: SelectableNode) => n.discriminator === DISCRIMINATOR.PN || n.address == null
    );
    if (nodes.length !== 1) {
      this.toastService.error({ message: this.SELECT_TO_MAP_UNMAP, dataCy: 'send-error-toast' });
      return;
    }
    if (this.selectedPnId == null) {
      this.unmapSelectedPN(nodes);
    } else {
      this.mapSelectedPN(nodes[0]);
    }
  }

  private mapSelectedPN(node: EnhancedSelectable): void {
    if (node.groupId != null) {
      this.toastService.error({ message: "Can't re-map a grouped PN", dataCy: 'send-error-toast' });
      return;
    }
    // An already mapped PN must be unmapped first
    if (node.properlyMapped) {
      this.toastService.error({
        message: 'This node is already mapped. Please unmap it first, before mapping it to a different virtual node',
        dataCy: 'send-error-toast'
      });
      return;
    }
    // Map passive node
    this.mapVirtualToActual(node);
  }

  private mapVirtualToActual(node: EnhancedSelectable): void {
    this.passiveNodeService
      .mapVirtual2Actual(new VirtualNotificationDTO(this.selectedPnId, node.id, this.buildingId))
      .subscribe({
        next: (next) => {
          this.toastService.success({
            message: `Virtual Node with ID: ${this.selectedPnId}, has been successfully mapped to actual node with id: ${node.id}`,
            dataCy: 'send-success-toast'
          });
          this.initialiseUnmappedPNList();
          this.reloadNodes();
        },
        error: (error) => {
          this.toastService.error({
            message: this.ERROR_MAPPING_VIRT,
            dataCy: 'send-error-toast'
          });
        }
      });
  }

  toggleNodes(): void {
    this.floorplanService.enableAllNodesMode = !this.floorplanService.isEnableAllNodesSelected;
  }

  toggleDrivers(): void {
    this.floorplanService.showDriversMode = !this.floorplanService.isShowDriversModeActive;
  }

  toggleSubscriberConnections(): void {
    this.floorplanService.showPubSubConnections = !this.floorplanService.arePubSubConnectionsEnabled;
  }

  toggleHeatmap(): void {
    this.floorplanService.heatmapMode = !this.floorplanService.isHeatmapModeActive;
  }

  toggleFaultyNodes(): void {
    this.floorplanService.faultyNodesMode = !this.floorplanService.isShowFaultyNodesModeActive;
  }

  toggleHighlightEmergencyLights(): void {
    this.floorplanService.highlightEmergencyLightsMode = !this.floorplanService.isHighlightEmergencyLightsModeActive;
  }

  toggleMappedNodes(): void {
    this.floorplanService.setMappedNodesMode = !this.floorplanService.showMappedNodesMode;
    this.floorplanService.showPubSubConnections = this.floorplanService.showMappedNodesMode;
    this.floorplanService.showDriversMode = this.floorplanService.showMappedNodesMode;
  }

  toggleUnmappedNodes(): void {
    this.floorplanService.unmappedMode = !this.floorplanService.isUnmappedModeActive;
  }

  toggleNormalizeScale(): void {
    this.floorplanService.scaleNormalized = !this.floorplanService.isScaleNormalized;
  }

  toggleLiveMode(): void {
    this.floorplanService.liveModeEnabled = !this.floorplanService.isLiveModeEnabled;
    // this.metricService.liveMode = this.floorplanService.isLiveModeEnabled;
  }

  toggleAddMode($event: MouseEvent | TouchEvent): void {
    this.floorplanService.addMode = !this.floorplanService.isAddModeEnabled;
    this.floorplanService.unmappedMode = this.floorplanService.isAddModeEnabled;
    this.addNodeClicked.emit(this.floorplanService.isAddModeEnabled);
    $event.stopPropagation();
  }

  toggleMoveMode(): void {
    this.floorplanService.moveMode = !this.floorplanService.isMoveModeActive;
  }

  toggleSensorNodeMappingMode(): void {
    this.mappingService.clearMappingFlags();
    this.floorplanService.sensorNodeMappingMode = !this.floorplanService.isSensorNodeMappingModeActive;
    this.floorplanService.unmappedMode = this.floorplanService.isSensorNodeMappingModeActive;
    this.snMappingModeClicked.emit(this.floorplanService.isSensorNodeMappingModeActive);
  }

  togglePassiveNodeMappingMode(): void {
    if (this.floorplanService.isSensorNodeMappingModeActive) {
      return;
    }
    this.floorplanService.pnMappingMode = !this.floorplanService.isPnMappingModeActive;
    this.floorplanService.unmappedMode = this.floorplanService.isPnMappingModeActive;
    if (this.floorplanService.isPnMappingModeActive) {
      this.floorplanService.addMode = false;
      this.floorplanService.moveMode = false;
      this.isGatewayModeAllowed = false;
    } else {
      this.isGatewayModeAllowed = true;
    }
    this.pnMappingModeClicked.emit(this.floorplanService.isPnMappingModeActive);
  }

  private toggleMoveAllMode(): void {
    this.floorplanService.moveAllMode = !this.floorplanService.isMoveAllModeActive;
  }

  public toggleGatewayMode(): void {
    this.floorplanService.gatewayMode = !this.floorplanService.isGatewayModeActive;
  }

  isGatewayModeEnabled(): boolean {
    return this.floorplanService.isGatewayModeActive;
  }
  onInitiateTest(testType: string): void {
    this.runTestsInBatch.emit(testType);
  }

  cancelTestsManually(): void {
    this.cancelTestsInBatch.emit('');
  }

  save(): void {
    this.saveAddedNodesClicked.emit();
  }

  discard(): void {
    this.discardAddClicked.emit();
  }

  undoAdd(): void {
    this.undoAddClicked.emit();
  }

  delete(): void {
    const nodeIds = this.sensorNodeService.selectedEntities.map((n) => n.id);
    if (nodeIds.length === 0) {
      return;
    }
    this.dialogService
      .open(
        new ConfirmDialogData(
          'Selected nodes and its drivers will be permanently deleted. Note: This will happen in the background, and may take some time!'
        )
      )
      .subscribe((value) => {
        if (value) {
          this.sensorNodeService.deleteSelectedNodes(nodeIds).subscribe(() => {
            this.reloadNodes();
          });
        }
      });
  }

  unmap(): void {
    const selectedNodes = this.sensorNodeService.selectedEntities;
    const somePassive = selectedNodes.some((node) => node.discriminator === DISCRIMINATOR.PN);
    const someSN3 = selectedNodes.some((node) => node.discriminator === DISCRIMINATOR.SN3);

    if (somePassive && someSN3) {
      this.toastService.error({ message: this.INVALID_NODE_TYPE_UNMAP_MSG, dataCy: 'send-error-toast' });
    } else if (somePassive) {
      this.unmapSelectedPN(selectedNodes);
    } else if (someSN3) {
      this.unmapSelectedSN3s(selectedNodes);
    }
  }

  private unmapSelectedPN(nodes: EnhancedSelectable[]): void {
    // all nodes in the "nodes" should be properlyMapped
    if (nodes.some((node) => !node.properlyMapped)) {
      this.toastService.error({ message: this.ONLY_MAPPED_CAN_BE_UNMAPPED, dataCy: 'send-error-toast' });
      return;
    }
    // Unmap passive node
    // For us to be able to unmap a PN it should be:
    // 1. A passive node
    // 2. Ungrouped or Grouped and no publishers in the floor
    const groupedPNs = nodes.filter((node) => node.discriminator === DISCRIMINATOR.PN && node.groupId != null);
    const groupedPNsPublisherInTheFloor = groupedPNs.filter((gpn) => {
      const selectable = this.sensorNodeService.currentFloorNodes.filter(
        (n: EnhancedSelectable) => n.discriminator === DISCRIMINATOR.SN3 && n.groupId === gpn.groupId
      );
      return selectable.length > 0;
    });
    if (groupedPNsPublisherInTheFloor.length !== 0) {
      this.toastService.error({
        message: `Can't unmap grouped PNs ${groupedPNsPublisherInTheFloor.map(
          (node) => node.id
        )} because they have publishers in the floor`,
        dataCy: 'send-error-toast'
      });
      return;
    }

    const passiveNodes = nodes.filter((node) => node.discriminator === DISCRIMINATOR.PN && node.properlyMapped);
    const nodeIds = passiveNodes.map((node) => node.id);
    if (nodeIds.length !== 0) {
      const batch = new SensorNodeIdsBatch(nodeIds);
      this.dialogService.open(new ConfirmDialogData(this.UNMAP_NODE_MSG)).subscribe((value) => {
        if (value) {
          this.mappingService.unmapGenericNodes(batch).subscribe({
            next: () => {
              this.toastService.info({
                message: `Successfully unmapped PN with IDs: ${nodeIds}`,
                dataCy: 'send-success-toast'
              });
              passiveNodes.forEach((node) => {
                node.address = null;
                node.properlyMapped = false;
              });
              this.initialiseUnmappedPNList();
              this.reloadNodes();
            },
            error: () => {
              this.toastService.error({ message: this.UNMAPPING_FAILED, dataCy: 'send-error-toast' });
            }
          });
        }
      });
    } else {
      this.toastService.error({ message: this.UNGROUPED_PNS_IN_SELECTION, dataCy: 'send-error-toast' });
    }
  }

  private initialiseUnmappedPNList(): void {
    this.passiveNodeService.getPassiveNodesList(this.buildingId).subscribe({
      next: (sensorNodes) => {
        this.availableUnmappedPNList = sensorNodes;
      },
      error: (error) => {
        this.toastService.error({ message: 'Failed to fetch passive nodes', dataCy: 'send-error-toast' });
      }
    });
  }

  private unmapSelectedSN3s(selectedNodes: EnhancedSelectable[]): void {
    const batch = new SensorNodeIdsBatch(selectedNodes.map((node) => node.id));
    if (batch.nodeIds.length === 0) {
      return;
    }
    const isGroupedSN3 = selectedNodes.some(
      (node) => node.groupId != null && (node.discriminator == null || node.discriminator === DISCRIMINATOR.SN3)
    );
    const message = isGroupedSN3 ? this.PUBLISHER_UNMAP_MSG : this.UNMAP_NODE_MSG;
    this.dialogService.open(new ConfirmDialogData(message)).subscribe((value) => {
      if (value) {
        this.mappingService.unmapGenericNodes(batch).subscribe({
          next: () => {
            this.toastService.success({ message: this.SN3_UNMAPPED_SUCCESS, dataCy: 'send-success-toast' });
            selectedNodes.forEach((node) => {
              node.address = null;
              node.properlyMapped = false;
              node.hasMappingBeenAttempted = false;
            });
            this.reloadNodes();
          },
          error: (error) => {
            this.toastService.error({ message: this.UNMAPPING_FAILED, dataCy: 'send-error-toast' });
          }
        });
      }
    });
  }

  private reloadNodes(): void {
    this.sensorNodeService.fetchNodes(this.floorId, this.buildingId);
    // TODO: figure out a way to reset flags based on view
    this.floorplanService.addMode = false;
    this.floorplanService.moveMode = false;
  }

  saveMove(): void {
    this.saveMoveClicked.emit();
  }

  undoMove(): void {
    this.undoMoveClicked.emit();
  }

  discardMove(): void {
    this.discardMoveClicked.emit();
  }

  toggleAreaSelection(): void {
    this.floorplanService.areaMode = !this.floorplanService.isAreaModeActive;
  }

  toggleCumulativeSelection(): void {
    this.floorplanService.cumulativeMode = !this.floorplanService.isCumulativeModeActive;
    if (this.floorplanService.isCumulativeModeActive) {
      this.floorplanService.enableAllNodesMode = true;
    }
  }

  selectAll(): void {
    this.sensorNodeService.clearSelection();
    const nodesToBeSelected = this.sensorNodeService.getAllNodes(this.floorplanService.isElmtPage);
    this.sensorNodeService.selectAll(nodesToBeSelected);
    this.sensorNodeService.updateQueryParams();
  }

  clear(): void {
    this.sensorNodeService.clearSelection();
    this.sensorNodeService.updateQueryParams();
    this.floorplanService.resetSelectedTags();
  }

  optionLabelForPassiveNode(pn: SensorNodeIdAndAddressDTO): string {
    return `Node addr: ${pn.address.toString(16).toUpperCase().padStart(6, '0')}, ID#${pn.id}`;
  }

  get isScaleNormalized(): boolean {
    return this.floorplanService.isScaleNormalized;
  }

  get isLiveModeEnabled(): boolean {
    return this.floorplanService.isLiveModeEnabled;
  }
}
